a, button {
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:focus, &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

.btn {
  display: inline;
  border: 1px solid $grey--4;
  background-color: $white;
  border-radius: 5px;
  font-size: 14px;
  min-width: 172px;
  font-weight: 500;
  line-height: 17px;
  padding: 14px 15px;
  color: $grey--7;
  cursor: pointer;

  &.btn-white {
    border-color: $white;
    color: $white;
  }

  &.btn-black-border {
    border-color: $black;
    color: $black;

    &:hover, &:focus, &:active {
      border-color: $black;
      color: $black;
    }
  }

  &.btn-lg {
    height: 59px;
    line-height: 59px;
    font-size: 16px;
    font-weight: 600;

  }

  &.btn-md {
    height: 59px;
    line-height: 57px;
    font-size: 16px;
    font-weight: 600;
    min-width: auto;
  }

  &:hover, &:focus, &:active {
    border: 1px solid $grey--4;
    background-color: $white;
    color: $grey--7;
  }
}

.btn-mini-black {
  flex: 1;
  height: 45px;
  border-radius: 10px;
  background-color: $white;
  border: 2px solid $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  color: $primary;


  &:hover, &:focus, &:active {
    background-color: $primary;
    border: 2px solid $primary;
    color: $white;
    font-size: 15px;
  }
}
.btn-green-quarter {
  display: inline;
  width: 25% !important;
  flex: 1;
  height: 47px;
  border-radius: 40px;
  background-color: #144C2F;
  border: 1px solid #144C2F;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 40px;
  margin-right: 20px;
  &:hover, &:focus, &:active {
    background-color: $white;
    border: 1px solid #144C2F;
    color: #144C2F;
    font-size: 22px;
    font-weight: 700;
  }
}
.btn-green-mini {
  display: inline;
  width: 20% !important;
  flex: 1;
  height: 30px;
  border-radius: 10px;
  padding: 3px 3px 3px;
  background-color: #144C2F;
  border: 1px solid #144C2F;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 3px;
  margin-right: 5px;
  &:hover, &:focus, &:active {
    background-color: $white;
    border: 1px solid #144C2F;
    color: #144C2F;
    font-size: 17px;
    font-weight: 700;
  }
}

.btn-orange-mini-mini {
  display: inline;
  width: 30% !important;
  flex: 1;
  height: 30px;
  border-radius: 10px;
  padding: 3px 3px 3px;
  background-color: #F4A460;
  border: 1px solid #F4A460;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 3px;
  margin-right: 5px;
  &:hover, &:focus, &:active {
    background-color: $white;
    border: 1px solid #F4A460;
    color: #F4A460;
    font-size: 17px;
    font-weight: 700;
  }
}

.btn-blue-mini-mini {
  display: inline;
  width: 30% !important;
  flex: 1;
  height: 30px;
  border-radius: 10px;
  padding: 3px 3px 3px;
  background-color: #87CEEB;
  border: 1px solid #87CEEB;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 3px;
  margin-right: 5px;
  &:hover, &:focus, &:active {
    background-color: $white;
    border: 1px solid #87CEEB;
    color: #87CEEB;
    font-size: 17px;
    font-weight: 700;
  }
}

.btn-orange-mini {
  display: inline;
  width: 10px !important;
  flex: 1;
  height: 40px;
  border-radius: 10px;
  background-color: $white;
  border: 1px solid $primary;
  align-items: center;
  justify-content: center;
  color: $primary;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-right: 5px;
  &:hover, &:focus, &:active {
    background-color: $primary;
    border: 1px solid $primary;
    color: $white;
    font-size: 17px;
    font-weight: 900;
  }
}
.btn-black-half {
  display: inline;
  width: 45% !important;
  flex: 1;
  height: 47px;
  border-radius: 40px;
  background-color: $black;
  border: 1px solid $black;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 40px;
  margin-right: 20px;
  &:hover, &:focus, &:active {
    background-color: $white;
    border: 1px solid $black;
    color: $black;
    font-size: 22px;
    font-weight: 700;
  }
  &.blue{
    background-color: #2F2DF7;
        &:hover, &:focus, &:active {
        background-color: $white;
        border: 1px solid $black;
        color: $black;
        font-size: 22px;
        font-weight: 700;
      }
  }
  &.green{
    background-color: #144C2F;
        &:hover, &:focus, &:active {
        background-color: $white;
        border: 1px solid $black;
        color: $black;
        font-size: 22px;
        font-weight: 700;
      }
  }
}
.btn-black {
  display: inline-block;
  width:100%;
  flex: 1;
  height: 47px;
  border-radius: 40px;
  background-color: $black;
  border: 1px solid $black;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 15px;
  &.next{
    background-color: #28417C;
  }
  &.prev{
    background-color: #D54C2F;
  }

  &.width-50 {
    width: 250px;
  }

  &.left {
    align-items: left;
    justify-content: left;
    margin-right : auto;
  }
  &.right {
    align-items: right;
    justify-content: right;
    margin-left : auto;
  }

  &:hover, &:focus, &:active {
    background-color: $white;
    border: 1px solid $black;
    color: $black;
    font-size: 16px;
    font-weight: 700;
  }

  &.btn-grey-border {
    border-color: $grey--2;
    color: $black;
    background-color: $white;

    &:hover, &:focus, &:active {
      border-color: $grey--2;
      color: $black;
      background-color: $white;
    }
  }

  &.btn-dark-grey-border {
    border-color: $grey--4;
    color: $grey--7;
    background-color: $white;

    &:hover, &:focus, &:active {
      border-color: $grey--4;
      color: $grey--7;
      background-color: $white;
    }
  }

  &.btn-black-border {
    border-color: 30px solid $black;
    color: $black;
    background-color: $white;

    &:hover, &:focus, &:active {
      border-color: $black;
      color: $white;
      background-color: $black;
      font-size: 16px;
    }
  }

  &.btn-primary-border {
    border-color: $primary;
    color: $primary;
    background-color: $white;

    &:hover, &:focus, &:active {
      border-color: $primary;
      color: $primary;
      background-color: $white;
    }
  }

  &.btn-red-border {
    border-color: $false;
    color: $false;
    background-color: $white;

    &:hover, &:focus, &:active {
      border-color: $false;
      color: $false;
      background-color: $white;
    }
  }
}
.txt-underline{
    text-align: center;
    text-decoration: underline;
    font-weight: 700;
}

.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-sort {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid $grey--2;
  background-color: $white;
  line-height: 17px;
  color: $grey--5;
  font-size: 14px;
  font-weight: 500;
  text-align: center;

  .num {
    margin-left: 6px;
  }

  &.on {
    color: $black;
    border-color: $black;
  }
}

.btn-sort-list {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 1200px) {
  .btn-sort-list {
    display: block;
    white-space: nowrap;
    overflow-x: auto;

    .btn-sort {
      display: inline-block;

      + .btn-sort {
        margin-left: 10px;
      }
    }
  }
}
