.ico {
  display: inline-block;
  background-size: cover;
}

.ico-search {
  width: 38px;
  height: 38px;
  background-image: url(../../public/img/common/search_button.svg);
}

.ico-main-top-1 {
  width: 40px;
  height: 40px;
  background-image: url(../../public/img/common/top_main_ico1.svg);
}

.ico-main-top-2 {
  width: 40px;
  height: 40px;
  background-image: url(../../public/img/common/top_main_ico2.svg);
}

.ico-main-top-3 {
  width: 40px;
  height: 40px;
  background-image: url(../../public/img/common/top_main_ico3.svg);
}

.ico-main-banner {
  width: 20px;
  height: 20px;
  background-image: url(../../public/img/common/banner_right_arrow_ico.svg);
}

.ico-price-up {
  width: 10px;
  height: 9px;
  background-image: url(../../public/img/common/price_up_ico.svg);
}

.ico-price-down {
  width: 10px;
  height: 9px;
  background-image: url(../../public/img/common/price_down_ico.svg);
}

.ico-price-down-red {
  width: 10px;
  height: 9px;
  background-image: url(../../public/img/common/ico-price-down-red.svg);
}

.ico-bottom-btn {
  width: 50px;
  height: 50px;
  background-image: url(../../public/img/common/mobile_menu_1.png);
}

.ico-filter-down {
  width: 20px;
  height: 20px;
  background-image: url(../../public/img/common/open_panel_ico_button_type.svg);
}

.ico-refresh {
  width: 20px;
  height: 20px;
  background-image: url(../../public/img/common/reset_filter_ico.svg);
}

.ico-modal-close {
  width: 24px;
  height: 24px;
  background-image: url(../../public/img/common/close_popup_ico.svg);
}

.ico-slide-prev {
  width: 24px;
  height: 24px;
  background-image: url(../../public/img/common/prev_thumbnail_ico.svg);
}

.ico-slide-next {
  width: 24px;
  height: 24px;
  background-image: url(../../public/img/common/next_thumbnail_ico.svg);
}

.ico-price-up-green {
  width: 10px;
  height: 9px;
  background-image: url(../../public/img/common/price_up_green_ico.svg);
}

.ico-seller {
  width: 10px;
  height: 10px;
  background-image: url(../../public/img/common/seller_ico.svg);
}

.ico-buyer {
  width: 10px;
  height: 10px;
  background-image: url(../../public/img/common/seller_ico_red.svg);
}

.ico-more-comment {
  width: 20px;
  height: 20px;
  background-image: url(../../public/img/common/more_comment_ico.svg);
}

.ico-post-like {
  width: 24px;
  height: 24px;
  background-image: url(../../public/img/common/post_like_ico.svg);
}

.on > .ico-post-like {
  width: 24px;
  height: 24px;
  background-image: url(../../public/img/common/post_like_ico_active.svg);
}

.ico-post-dislike {
  width: 24px;
  height: 24px;
  background-image: url(../../public/img/common/post_dislike_ico.svg);
}

.on > .ico-post-dislike {
  width: 24px;
  height: 24px;
  background-image: url(../../public/img/common/post_dislike_ico_active.svg);
}

.ico-modal-close-white {
  width: 24px;
  height: 24px;
  background-image: url(../../public/img/common/ico-popup-close-white.svg);
}

.ico-completed {
  width: 80px;
  height: 80px;
  background-image: url(../../public/img/common/img-completed.svg);
}

.ico-notification {
  width: 25px;
  height: 25px;
  background-image: url(../../public/img/common/ico_noti.svg);
}

.ico-shop {
  width: 25px;
  height: 25px;
  background-image: url(../../public/img/common/ico_shop.svg);
}

.ico-login {
  width: 25px;
  height: 25px;
  background-image: url(../../public/img/common/ico_login.svg);
}

.ico-go-link {
  width: 20px;
  height: 20px;
  background-image: url(../../public/img/common/mypage_right_arrow_ico.svg);
}

.ico-mypage-1 {
  width: 45px;
  height: 45px;
  background-image: url(../../public/img/common/ico-mypage-1.svg);
}


.ico-mypage-2 {
  width: 45px;
  height: 45px;
  background-image: url(../../public/img/common/ico-mypage-2.svg);
}


.ico-mypage-3 {
  width: 45px;
  height: 45px;
  background-image: url(../../public/img/common/ico-mypage-3.svg);
}


.ico-mypage-4 {
  width: 45px;
  height: 45px;
  background-image: url(../../public/img/common/ico-mypage-4.svg);
}

.ico-camera {
  width: 18px;
  height: 16px;
  background-image: url(../../public/img/common/change_photo_ico.svg);
}

.ico-empty {
  width: 56px;
  height: 56px;
  background-image: url(../../public/img/common/ico-empty.svg);
}

.ico-heart {
  width: 24px;
  height: 20px;
  background-image: url(../../public/img/common/btn-like.svg);
}

.on {
  .ico-heart {
    width: 24px;
    height: 20px;
    background-image: url(../../public/img/common/btn-like-on.svg);
  }
}

.ico-nav-prev {
  width: 20px;
  height: 20px;
  background-image: url(../../public/img/common/prev_page_ico.svg);
}

.ico-nav-next {
  width: 20px;
  height: 20px;
  background-image: url(../../public/img/common/next_page_ico.svg);
}

.ico-prev-grey {
  width: 20px;
  height: 20px;
  background-image: url(../../public/img/common/prev_arrow_ico.svg);
}

.ico-next-grey {
  width: 20px;
  height: 20px;
  background-image: url(../../public/img/common/next_arrow_ico.svg);
}

.ico-favorite {
  width: 16px;
  height: 14px;
  background-image: url(../../public/img/common/fav_ico_on.svg);
}
