.popup-bottom {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 30;
  background-color: #fff;

  .popup-select-category {
    .title {
      padding-top: 30px;
      padding-bottom: 21px;
      border-bottom: 1px solid $grey--0;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }

    .select-menu {
      height: 265px;
      overflow-y: auto;
      background-color: $grey--0;
      padding: 20px;

      .item {
        cursor: pointer;
        width: 100%;
        height: 37px;
        border: 1px solid $grey--2;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        background-color: $white;

        span {
          font-size: 14px;
          font-weight: 500;
          color: $grey--6;
        }

        &.on {
          border-color: $black;

          span {
            color: $black;
          }
        }

        + .item {
          margin-top: 10px;
        }

        span {
          display: block;
        }
      }
    }
  }

  .popup-filter {
    .title {
      position: relative;
      border-bottom: 1px solid $grey--1;
      font-size: 14px;
      font-weight: 600;
      padding: 30px 20px 21px;

      .state3 {
        position: absolute;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $grey--6;
        font-weight: 500;
        top: 15px;
        right: 15px;

        .ico {
          margin-right: 5px;
        }
      }
    }

    .filter-box {
      background-color: transparent;
      border-radius: 0;
      padding: 0;
      border: 0;
      width: 100%;

      .filter-body {
        display: block;
        overflow-y: auto;
        height: 350px;
        padding: 0 20px;

        .type-list {
          margin-bottom: 20px;

          &::after {
            width: calc(100% + 40px);
          }

          .item {
            margin-top: 10px;

            .label {
              margin-bottom: 10px;
            }

            &.item-half {
              width: 100%;

              + .item-half {
                margin-left: 0;
              }
            }
          }
        }

        .range-list {
          margin-bottom: 20px;

          &::after {
            content: none;
          }

          .item {
            &.item-half {
              width: 100%;

              + .item-half {
                margin-left: 0;
              }
            }
          }
        }
      }

      .filter-footer {
        display: flex;
        border-top: 1px solid $grey--1;
        padding: 20px 20px 30px;
      }
    }

    .align-box {
      padding: 20px 20px 30px;

      .item {
        button {
          display: block;
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;
          color: $grey--5;
        }

        &.on {
          button {
            color: $black;
          }
        }

        + .item {
          margin-top: 20px;
        }
      }
    }
  }

  .popup-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $grey--0;
    padding: 21px 20px 30px;
    background-color: $white;

    .btn-black {
      + .btn-black {
        margin-left: 15px;
      }
    }
  }
}

.backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.9;
  background: $black;
  z-index: 19;

  &.show {
    display: block;
  }
}

@media (max-width: 1200px) {
  .popup-bottom {
    &.show {
      display: block;
    }
  }
}
@media (min-width: 1200px) {
  .backdrop {
    display: none;
  }

  .backdrop.show {
    display: none;
  }
}

.btn-modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  width: 24px;
  height: 24px;
}

.modal-dialog {
  max-width: 600px;
}




.modal {
  &.modal-green {
    .modal-content {
      background-color: $primary;
      border-radius: 10px;
    }

    .content {
      padding: 30px 20px;

      > .title {
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        text-align: center;
        margin-bottom: 10px;
        color: $white;
      }

      > .desc {
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 30px;
      }
    }

    .btn-wrap {
      gap: 15px;

      .btn {
        display: inline-block;
        min-width: auto;
        flex: none;
        padding: 0 80px;
        background-color: rgba(30, 30, 32, .3);
        border: 0;

      }
    }

    .box {
      border-radius: 10px;
      background: rgba(255,255,255,.1);
      text-align: center;
      padding: 30px 0;
      margin-bottom: 30px;

      .val {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: $white;
      }
    }

    .sign-in-completed {
      text-align: center;

      .ico-completed {
        display: block;
        margin: 0 auto 20px;
      }

      .txt {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        color: $white;
        margin-bottom: 30px;
      }
    }
  }

  &.modal-white {
    .modal-content {
      background-color: $white;
      border-radius: 10px;
    }

    .content {
      padding: 30px 20px;

      > .title {
        font-size: 21px;
        font-weight: bold;
        line-height: 19px;
        text-align: center;
        margin-bottom: 20px;
      }

      > .desc {
        font-size: 17px;
        line-height: 18px;
        text-align: left;
        color: $grey--7;
        margin-bottom: 30px;
      }
    }

    .btn-wrap {
      margin-top: 30px;
      gap: 15px;

      .btn {
        display: inline-block;
        min-width: auto;
        flex: none;
        padding: 0 80px;
        //background-color: rgba(30, 30, 32, .7);
        //border: 0;
      }
    }

    .box {
      border-radius: 10px;
      text-align: center;
      padding: 20px 0;
      margin-bottom: 20px;
      background-color: $grey--1;
      border: 1px solid $grey--1;//$false;
      font-weight:400;

      .val {
        display: block;
        font-size: 17px;
        font-weight: 600;
        line-height: 17px;
        color: $false;
        margin-bottom:5px;
        > .items {
            display: block;
            font-size: 14px;
            margin-left:20px;
            text-align: left;
            margin-top: 10px;
            color: $black;
            > .item {
                margin-left:40px;
            }
            > .item-bold {
                display: inline;
                font-weight:600;
            }
            &.bottom {
                margin-bottom:10px;
            }
            &.bottom-double {
                margin-bottom: 50px;
            }
        }
      }
    }

    .tip {
      font-size: 15px;
      line-height: 14px;
      text-align: center;
      color: $grey--6;
    }
  }

  &.modal-red {
    .modal-content {
      background-color: #E2486D;
      border-radius: 10px;
    }

    .content {
      padding: 30px 20px;

      > .title {
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        text-align: center;
        margin-bottom: 10px;
        color: $white;
      }

      > .desc {
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        color: rgba(255, 255, 255, .7);
        margin-bottom: 30px;
      }
    }

    .img {
      width: 80px;
      height: 80px;
      margin: 0 auto 20px;
    }

    .txt {
      font-size: 16px;
      font-weight: 500;
      color: $white;
      line-height: 30px;
      text-align: left;
      padding-left: 50px;
    }

    .btn-wrap {
      margin-top: 30px;
      gap: 15px;
      min-width: auto;

      .btn {
        display: inline-block;
        min-width: auto;
        flex: none;
        padding: 0 80px;
        background-color: rgba(30, 30, 32, .3);
        //border: 0;
      }
    }

    .box {
      border-radius: 10px;
      background: rgba(255,255,255,.1);
      text-align: center;
      padding: 30px 0;
      margin-bottom: 30px;

      .val {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: $white;
      }
    }

    .sign-in-completed {
      text-align: center;

      .ico-completed {
        display: block;
        margin: 0 auto 20px;
      }

      .txt {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        color: $white;
        margin-bottom: 30px;
      }
    }
  }

  .option-box {
    .list-box {
      background-color: transparent;
      min-height: auto;
      padding: 0;
      border-radius: 0;

      > .item {
        padding: 0;

        .info {
          margin-top: 0;

          .img {
            width: 50px;
            height: 50px;
          }

          .title {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }

    + .btn-wrap {
      + .btn-wrap {
        display: none;
      }
    }
  }
}

.modal-content {
  border: 0;
  border-radius: 0;
}

.modal-bidding-check {
  .content {
    padding: 30px 20px;

    > .title {
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
      text-align: center;
      margin-bottom: 10px;
    }

    > .desc {
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      margin-bottom: 20px;
      color: $grey--6;
    }
  }

  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    .item {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: $grey--3;

      &.on {
        background-color: $primary;
      }

      + .item {
        margin-left: 15px;
      }
    }
  }

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #ededf3;
    background: #ffffff;
    padding: 20px 15px;

    .title {
      line-height: 14px;
      font-size: 12px;
      font-weight: 500;
      color: $grey--6;
    }

    .txt {
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      gap: 10px;

      .ele {
        width: calc(50% - 5px);
      }
    }

    + .box {
      margin-top: 10px;
    }

    &.vertical {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        margin-bottom: 10px;
      }
    }

    &.box-center {
      justify-content: center;
      border-color: $primary;

      .title {
        color: $primary;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .caution {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    color: $grey--6;
    margin-top: 20px;

    .color {
      color: #000;
    }
  }

  .form-group {
    position: relative;
    margin-top: 20px;
    padding-top: 20px;

    &.moblie {
        margin-left: 0px;
    }

    &::before {
      content: '';
      position: absolute;
      width: calc(100% + 40px);
      height: 1px;
      background-color: $grey--1;
      left: -20px;
      top: 0;
    }

    .label {
      font-size: 16px;
      color: $grey--7;
      line-height: 14px;
      margin-bottom: 10px;
      font-weight: 500;
    }

    .form-box {
      position: relative;

      .form-control {
        height: 47px;
        font-size: 17px;
        font-weight: 500;
        border-radius: 5px;
        padding: 0 40px 0 15px;
      }

      .unit {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 17px;
        font-weight: 500;
      }

    }
  }

  .btn-wrap {
    margin-top: 30px;

    .btn {
      //flex: 1;
      flex: none;
      display: inline-block;
      padding: 0 80px;

      &.btn-mo {
        display: none;
      }
    }
  }

  &.completed {
    .modal-content {
      background-color: $primary;
    }

    .content {
      > .title {
        color: $white;
      }

      > .desc {
        color: rgba(255, 255, 255, .7);
        margin-bottom: 30px;
      }
    }

    .box {
      img {
        margin-bottom: 15px;
      }
      &.vertical {
        background-color: rgba(255, 255, 255, .1);
        align-items: center;
        padding: 30px 20px;
        border: 0;

        .title {
          color: rgba(255, 255, 255, .7);
          margin-bottom: 15px;
        }

        .title-lg {
          font-size: 20px;
          line-height: 24px;
          font-weight: 600;
          color: $white;
        }
      }
    }

    .btn-wrap {
      .btn {
        background-color: rgba(30, 30, 32, .7);
        border: 0;
      }
    }
  }

  &.type2 {
    .modal-content {
      border-radius: 10px;
      border: 0;
      overflow: hidden;
    }

    .btn-wrap {
      .btn {
        flex: none;
        padding: 0 80px;
      }
    }

    &.completed {
      .btn-wrap {
        .btn {
          background-color: rgba(30, 30, 32, .3);
        }
      }

      .img {
        width: 80px;
        height: 80px;
        margin: 0 auto 20px;
      }

      .txt-lg {
        text-align: center;
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 15px;
        color: #fff;
        &.right{
            margin-left: 0px;
          }
      }

      .txt {
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: #fff;
        opacity: .7;
      }
    }
  }
}

@media (max-width: 768px) {
  .modal-bidding-check {
    .box {
      flex-direction: column;
      align-items: flex-start;

      &.vertical {
        .txt {
          .ele {
            width: 100%;
          }
        }
      }

      .title {
        margin-bottom: 5px;
      }

      .txt {
        width: 100%;
        justify-content: flex-end;
      }
    }

    .btn-wrap {
      .btn {
        &.btn-mo {
          display: flex;
        }

        + .btn {
          margin-left: 15px;
        }
      }
    }

    &.type2 {
      .modal-dialog {
        align-items: center;
        padding: 0 20px;
      }

      .btn-modal-close {
        display: block;
      }

      .btn-wrap {
        .btn {
          padding: 0 10px;
        }
      }
    }

    &.completed {
      .modal-dialog {
        align-items: center;
        padding: 0 20px;
      }

      .btn-modal-close {
        display: block;
      }

      .modal-content {
        border-radius: 10px;
      }
    }
  }

  .modal-dialog {
    align-items: flex-end;
    max-width: none;
    margin: 0;
    height: 100%;

    .btn-modal-close {
      display: none;
    }
  }

  .modal {
    &.modal-green, &.modal-red {
      .modal-dialog {
        align-items: center;
        padding: 0 20px;
      }

      .btn-modal-close {
        display: block;
      }

      .btn-wrap {
        .btn {
          border: 0;
          flex: 1;
        }
      }
    }

    &.modal-white {
      .modal-content {
        border-radius: 0;
      }
    }

    &.modal-green {
      .sign-in-completed {
        .txt {
          padding: 0 20px;
          line-height: 22px;
        }
      }
    }

    .option-box {
      + .btn-wrap {
        .btn {
          flex: 1;
          padding: 0;
        }

        + .btn-wrap {
          display: flex;
          margin-top: 15px;

          .btn {
            flex: 1;
          }
        }
      }
    }
  }
}

.modal-calendar {
  .modal-content {
    border-radius: 10px;
  }

  .content {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
  }

  .desc {
    color: $grey--6;
    line-height: 12px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
  }

  .btn-wrap {
    .btn {
      flex: none;
    }
  }
}

.modal-reply-detail {
  .this-reply {
    padding: 30px 20px 21px;
    border-bottom: 1px solid $grey--1;
  }

  .user-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .name {
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
      margin-right: 10px;
    }

    .state {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 600;

      .ico {
        margin-right: 5px;
      }
    }
  }

  .txt {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .date {
    font-size: 12px;
    line-height: 12px;
    color: $grey--5;
    margin-bottom: 10px;
  }


  .reply-list {
    background-color: $grey--0;
    height: 300px;
    overflow-y: auto;

    .item {
      padding: 20px;

      .ui-like {
        border-top: 1px solid $grey--2;
        padding-top: 15px;
        margin-top: 15px;
      }

      + .item {
        border-top: 1px solid $grey--2;
      }
    }
  }

  .write-reply {
    position: relative;
    width: 100%;
    //height: 57px;
    background-color: $grey--2;
    padding: 20px;

    .input {
      width: 100%;
      //height: 100%;
      border: 0;
      padding: 0 80px 0 0;
      font-size: 14px;
      font-weight: 500;
      background-color: transparent;

      overflow-y: auto;
      max-height: 134px;
      display: block;

      &:focus, &:active, &:hover {
        border: 0;
        outline: none;
      }

      &::placeholder {
        color: $grey--5;
      }
    }

    .btn-submit {
      position: absolute;
      right: 20px;
      top: 50%;
      font-size: 14px;
      font-weight: 600;
      transform: translateY(-50%);
      z-index: 1;
    }

    .user {
      //position: absolute;
      //left: 20px;
      //top: 50%;
      font-size: 14px;
      font-weight: 600;
      //transform: translateY(-50%);
      color: $primary;
      z-index: 1;
    }

    &.with-user-name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 20px;

      .input {
        flex: 1;
        padding: 0 80px 0 0;
        margin-top: 5px;
      }
    }
  }
}

@media (max-width: 768px) {
  .modal-calendar {
    .modal-content {
      border-radius: 0;
    }

    .btn-wrap {
      padding: 0 20px;

      .btn {
        flex: 1;
      }
    }
  }

  .modal-reply-detail {
    .btn-modal-close {
      display: block;
    }

    .write-reply {
      &.with-user-name {
      }
    }
  }
}
