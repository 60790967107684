* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-family-base;
  color: $black;
}

a, button {
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

em, i {
  font-style: normal;
}

table {
  width: 100%;
  border-collapse: collapse;
}

ul, ol {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a, button {
  color: inherit;
  background-color: transparent;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

body.scrOff {
  overflow: hidden;
}

.multi-range-slider {
  border: 0;
  box-shadow: none;

  .bar-inner {
    background-color: $primary;
    border: 0;
    box-shadow: none;
  }

  .bar-left, .bar-right {
    box-shadow: none;
  }

  .thumb {
    &::before {
      width: 16px;
      height: 16px;
      border: 1px solid $grey--4;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.20);
      margin: -5px -6px;
    }

    .caption {
      display: none;

      * {
        background-color: $primary;
        box-shadow: none;
      }
    }
  }

  .labels {
    .label {
      white-space: nowrap;
    }
  }
}

.show-mo {
  display: none !important;
}

@media (max-width: 1200px) {
  .show-pc {
    display: none !important;
  }

  .show-mo {
    display: block !important;
  }
}

.color-primary {
  color: $primary !important;
  &.complete {
    font-size: 13px;
  }
}
.color-red-normal {
  color: red !important;
  font-size: 13px;
  &.complete {
    font-size: 13px;
  }
}

.color-red {
  color: red !important;
  font-size: 13px;
  position: absolute;
  right: 125px;
  top: 50%;
  transform: translateY(-50%);
}

.color-red-icon {
  color: red !important;
  font-size: 15px;
  font-weight: 500;
  &::before {
    content: "";
    display: inline-block;
    background-image: url(../../public/img/common/ico-warning.svg);
    background-size: 15px 15px;
    filter: invert(16%) sepia(99%) saturate(7404%) hue-rotate(4deg) brightness(95%) contrast(118%);
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.color-blue-icon {
  color: blue !important;
  font-size: 15px;
  font-weight: 500;
  &::before {
    content: "";
    display: inline-block;
    background-image: url(../../public/img/common/ico-complete.svg);
    background-size: 15px 15px;
    filter: invert(10%) sepia(90%) saturate(5268%) hue-rotate(245deg) brightness(109%) contrast(155%);
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.color-false {
  color: $false !important;
}

.color-white {
  color: white !important;
}

.color-black {
  color: $black !important;
}

.color-grey-6 {
  color: $grey--6 !important;
}

.color-grey-5 {
  color: $grey--5 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-18-with-check {
  font-size: 18px !important;
  font-weight: 700;
  color: $black;
  margin-left: 10px;
  vertical-align: top;
}

.font-17-with-check {
  font-size: 17px !important;
  font-weight: 600;
  color: $black;
  margin-left: 10px;
  vertical-align: top;
}

.font-15-with-check {
  font-size: 16px !important;
  font-weight: 500;
  color: $black;
  margin-left: 10px;
  vertical-align: top;
}

.left-50 {
  margin-left: 40px;
}
.left-30-per {
  margin-left: 30%;
}
.left-50-per {
  margin-left: 51%;
}

.left-100 {
  margin-left: 70px;
}

.font-16 {
  font-size: 16px !important;
  font-weight: 200;
  color: $black;
}

.font-14 {
  font-size: 14px !important;
}

.badge-price {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  border-radius: 50px;

  .ico {
    margin-left: 10px;
  }

  &.badge-primary {
    background-color: $primary;
  }

  &.badge-red {
    background-color: $false;
  }

  &.badge-none {
    background-color: transparent;
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    color: $black;
  }
}

.mt-30 {
  margin-top: 30px !important;
}
