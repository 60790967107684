.inner {
  width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.mini-rec {
  display: inline-block;
  width: 15%;
  height: 30px;
  background: black;
  padding: 5px 5px;
  margin-left: 3px;
  margin-bottom: 5px;
  background-color : $grey--1;
  font-weight: 500;
  text-align: center;
  &.off {
    background-color : $grey--4;
    &:hover, &:focus, &:active {
        background-color: $grey--4;
      }
  }
  &.over {
    background-color : $grey--4;
    &:hover, &:focus, &:active {
        background-color: $grey--4;
      }
  }
  &:hover, &:focus, &:active {
    background-color: #36ADD5;
    opacity:0.5;
  }

  &.green {
    background-color: #006400;
    opacity:0.8;
    &:hover, &:focus, &:active {
        background-color: #006400;
        opacity:0.5;
    }
  }

  &.greenLight {
    background-color: #006400;
    opacity:0.5;
    &:hover, &:focus, &:active {
        background-color: #006400;
        opacity:0.3;
    }
  }
  &.red {
    background-color: #982928;
    opacity:0.8;
    &:hover, &:focus, &:active {
        background-color: #982928;
        opacity:0.5;
    }
  }

  &.redLight {
    background-color: #982928;
    opacity:0.5;
    &:hover, &:focus, &:active {
        background-color: #982928;
        opacity:0.3;
    }
  }

  &.blank {
    margin-left: 2%;
  }
  &.click {
    background-color: #36ADD5;
  }
}
.indent {
    margin-left: 60px;
}
.room-rec {
  display: inline-block;
  width: 100px;
  height: 30px;
  background: black;
  padding: 5px 5px;
  margin-right: 8px;
  border-radius: 5px 5px 5px 5px;
  margin-bottom: 5px;
  background-color : $primary;
  opacity : 0.5;
  color : white;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  align-items: center;
  &.left {
    margin-left: 60px;
  }
  &.reverse {
    background-color : white;
    color : $primary;
    border:3px solid $primary;
    opacity: 1;
    padding: 3px 3px 3px 3px;
  }
  &.on {
    background-color : $primary;;
    font-weight: 700;
    opacity: 1;
  }
  &.green {
    background-color : #24A035; //#246C35;
    font-weight: 700;
    opacity: 1;
    width: 50px;
  }
}

.time-rec {
  display: inline-block;
  width: 70px;
  height: 30px;
  background: black;
  padding: 5px 5px;
  margin-right: 8px;
  border-radius: 5px 5px 5px 5px;
  margin-bottom: 0;
  background-color : $primary;
  opacity : 0.5;
  color : white;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  align-items: center;
  &.reverse {
    background-color : white;
    color : $primary;
    border:3px solid $primary;
    opacity: 1;
    padding: 3px 3px 3px 3px;
  }
  &.on {
    background-color : $primary;;
    font-weight: 700;
    opacity: 1;
  }
  &.green {
    background-color : #24A035; //#246C35;
    font-weight: 700;
    opacity: 1;
    width: 50px;
  }
}

.line-rec {
  display: inline;
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color : $grey--1;
  font-weight: 700;
  text-align: center;

  &.up {
    border-radius: 10px 10px 0 0;
    width: 18%;
    padding: 10px 20px;
    height: 40px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  &.on {
    background-color : $primary;
    color : white;
  }
}

.line-rec-mobile {
  display: inline;
  width: 100%;
  height: 35px;
  padding: 5px 5px;
  margin-right: 5px;
  margin-bottom: 1px;
  margin-top: 30px;
  background-color : $grey--1;
  font-weight: 700;
  text-align: center;

  &.up {
    border-radius: 10px 10px 0 0;
    width: 18%;
    padding: 5px 10px;
    height: 35px;
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 20px;
  }
  &.on {
    background-color : $primary;
    color : white;
    margin-top: 20px;
  }
}

.line {
  width: 100%;
  display: inline-block;
  border: 2px solid $grey--3;
  &.left-50 {
    margin-left: 10%;
    width: 90%;
  }
}

.sub-line {
  width: 100%;
  display: inline-block;
  border: 1px solid $grey--5;
  margin-left: 20px;
}

.sub-rec {
  display: block;
  margin-top:20px;
  margin-left:40px;
  margin-right: 40px;
  background-color: $grey--1;
  color: $grey--7;
  border-radius : 10px;
  padding: 20px 20px;
}

.rectangle {
  display: inline-block;
  width: 19%;
  height: auto;
  background: black;
  padding: 5px 5px;
  margin-left: 1%;
  margin-bottom: 10px;
  background-color : $grey--1;
  font-weight: 700;
  text-align: center;
  &.on {
    background-color : $primary;
    color: white;
    font-weight: 700;
  }
  &.left {
    border-radius: 10px 0px 0px 10px;
  }
  &.right {
    border-radius: 0px 10px 10px 0px;
  }

}
@media (max-width: 1200px) {
    .rectangle {
      display: inline-block;
      width: 19%;
      height: auto;
      background: black;
      font-size: 12px;
      padding: 5px 5px;
      margin-left: 1%;
      margin-bottom: 20px;
      background-color : $grey--1;
      font-weight: 600;
      text-align: center;
      &.mobile {
        font-size: 12px;
      }
      &.on {
        background-color : $primary;
        color: white;
        font-weight: 700;
      }
      &.left {
        border-radius: 10px 0px 0px 10px;
      }
      &.right {
        border-radius: 0px 10px 10px 0px;
      }

  }
}


.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
//   border-bottom: 1px solid $grey--1;

  &.main {
    border-bottom: 0;
  }

  .brand {
    position: absolute;
    left: 44%;
    top: 100%;
    transform: translateY(-50%);
    width: 76px;

    .logo {
      display: block;
      width: 100%;


      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .pc-menu {
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    a + a {
      margin-left: 25px;
    }

    .btn-top {
      position: relative;
      width: 25px;
      height: 25px;

      &.on {
        .ico-notification {
          background-image: url(../../public/img/common/noti_ico_active.svg);
        }

        &::before {
          content: '';
          width: 45px;
          height: 45px;
          position: absolute;
          background-color: $primary;
          opacity: .2;
          border-radius: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, - 50%);
        }
      }
    }

    .btn-go-shop {
      width: 25px;
      height: 25px;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .btn-go-login {
      display: flex;
      align-items: center;
    }

    .txt {
      font-size: 13px;
      font-weight: 500;
      margin-left: 10px;
      line-height: 25px;
    }
  }

  &.detail {
    justify-content: space-between;
    padding: 34px 35px 25px;
    border-bottom: 0;

    .brand {
      position: static;
      transform: none;
      margin-bottom: 0;
    }

    .pc-menu {
      position: static;
      transform: none;
    }
  }
}

.search-box {
  position: relative;
  display: flex;
  border: 1px solid $primary;
  border-radius: 30px;
  height: 48px;
  z-index: 20;
  background-color: $white;

  &.on {
    .select-menu {
      display: block;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 180px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 15px;
    background-color: $grey--1;
  }

  .select-box {
    position: relative;
  }

  .btn-select {
    width: 160px;
    height: 100%;
    border: 0;
    text-align: left;
    padding: 0 0 0 25px;
    -webkit-appearance: none;
    background-color: transparent;
    background-image: url(../../public/img/common/select_box_arrow_ico.svg);
    background-size: 12px 6px;
    background-repeat: no-repeat;
    background-position: right center;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-right: 40px;
  }

  .select-menu {
    display: none;
    position: absolute;
    width: 180px;
    background-color: $white;
    top: 63px;
    left: 0;
    border-radius: 5px;
    padding: 10px;

    ul {
      max-height: calc(37px * 5 + 10px * 4);
      overflow-y: auto;
    }

    .item {
      display: flex;
      align-items: center;
      height: 37px;
      border: 1px solid $grey--2;
      border-radius: 5px;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;

      &.on {
        border-color: $black;

        span {
          color: $black;
        }
      }

      span {
        display: block;
        width: 100%;
        height: 100%;
        line-height: 35px;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: $grey--6;
        padding: 0 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      + .item {
        margin-top: 10px;
      }
    }
  }

  .s-input {
    width: 300px;
    height: 100%;
    border: 0;
    background-color: transparent;
    font-size: 14px;
    padding-right: 45px;
    padding-left: 1px;

    &::placeholder {
      color: $grey--6;
    }
  }

  .btn-search {
    width: 38px;
    height: 38px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.footer {
  margin-top: 60px;
  padding-top: 60px;
  border-top: 1px solid $grey--1;

  .inner {
    display: flex;
    justify-content: space-between;
  }

  .links {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    line-height: 16px;

    li {
      line-height: 16px;

      + li {
        margin-left: 22px;
      }
    }

    a {
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  .detail {
    line-height: 14px;

    span {
      display: inline-flex;
      line-height: 14px;
      font-size: 12px;
      color: $grey--6;

      a {
        color: $grey--7;
        margin-left: 5px;
        text-decoration: underline;
      }

      + span {
        &::before {
          content: '.';
          margin: 0 10px;
        }
      }
    }

    + .detail {
      margin-top: 10px;
    }
  }

  .copyright {
    margin-top: 60px;
    padding-bottom: 60px;
    font-size: 12px;
    line-height: 14px;
  }

  .footer-logo {
    width: 150px;
    height: 60px;

    a {
      display: block;

      img {
        width: 100%;
        height: 100%;
        opacity: 0.5;
      }
    }

    &.mobile {
      display: none;
    }
  }
}

.mo-menu {
  display: none;
  position: fixed;
  bottom: 12px;
  right: 20px;
  z-index: 20;
  text-align: right;
  background-color : rgba(202, 165, 196, 1.0);
  border: 2px solid #614793;
  border-radius: 300px;
  padding: 5px 7px;


  &.show {
    border: 0px solid #614793;
    background-color : transparent;
    .btn-fixed {
      .ico-bottom-btn {
        color:red;
        background-image: url(../../public/img/common/menu_close_button.svg);
      }
    }

    + .backdrop {
      display: block;
    }

    .menu {
      display: block;
    }
  }

  .menu {
    display: none;
    margin-bottom: 22px;

    .item {
      background-color: $white;
      border-radius: 40px;
      min-width: 160px;

      &.register {
        background-color: $primary;

        span {
          color: $white;
        }
      }

      + .item {
        margin-top: 15px;
      }

      a {
        display: flex;
        align-items: center;
        padding: 15px 20px;
      }

      span {
        margin-left: 10px;
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
      }
    }
  }

  .btn-fixed {
    display: inline-block;
    width: 50px;
    height: 50px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.00);
  }

  &.main {
    bottom: 35px;
  }
}

@media (max-width: 1200px) {
  .inner {
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 10px 10px;
//     border-bottom: 1px solid $grey--1;

    &.main {
        margin-bottom : 60px;
//         border-bottom: 1px solid $grey--1;
    }

    .brand {
      width: 51px;
      top: 12px;
      left: 20px;
      transform: none;
      margin-bottom: 30px;
      img {
        margin-bottom : 30px;
      }
    }

    .pc-menu {
      display: none;
    }

    &.detail {
      padding: 12px 20px 17px;
      border-bottom: 0;
    }
  }

  .search-box {
    width: 100%;
    z-index: 18;

    &.on {
      .select-menu {
        display: none;
      }
    }

    &::after {
      left: 140px;
    }

    .btn-select {
      width: 130px;
      margin-right: 21px;
      font-size: 13px;
    }

    .s-input {
      width: calc(100% - 21px - 130px);
      font-size: 13px;
    }

    .btn-search {
      width: 36px;
      height: 36px;

      .ico {
        width: 36px;
        height: 36px;
      }
    }
  }

  .footer {
    margin-top: 45px;
    padding: 45px 20px;

    .inner {
      flex-direction: column;
    }

    .links {
      font-size: 13px;
      line-height: 16px;
    }

    .detail {
      span {
        display: block;

        &.address {
          margin-bottom: 10px;
        }

        + span {
          margin-top: 5px;

          &::before {
            content: none;
          }
        }
      }

      &.detail-last {
        margin-top: 5px;
      }
    }

    .copyright {
      padding-bottom: 0;
      margin-top: 20px;
    }

    .footer-logo {
      display: none;

      &.mobile {
        display: block;
        margin-bottom: 20px;
      }
    }
  }

  .mo-menu {
    display: block;
  }
}
