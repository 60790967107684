input, select, textarea {
  &:focus {
    outline: none;
  }
}

.calc {
    text-align : right;
    margin-bottom : 15px;
    font-size : 15px;
    font-weight: 900;
    color: black;
    &.highlight{
        color: red;
        font-weight: 800;
        font-size : 20px;
    }
    &.highlight{
        color: black;
        font-weight: 1000;
        font-size : 18px;
    }
}



.form-group-asterisk {
  &::before {
    content: '*';
    color: red !important;
    padding: 0 5px;
  }

  .label {
    line-height: 20px;
    font-size: 17px;
    color: $grey--7;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top:30px;
}

  + .form-group {
    margin-top: 30px;
  }

}

.table-agree {
    width: 100%;
    border: 1px solid #444444;
    border-collapse: collapse;
}
.th-agree {
    border: 1px solid #444444;
 }

.td-agree {
    border: 1px solid #444444;
 }
.th-agree {
    border: 1px solid #444444;
    text-align: center;
 }

.form-group {
  &.moblie {
        margin-left: 0;
    }
  &.right {
        text-align: right;
        margin-top: 5px;
        margin-bottom: 5px;
  }
  &.bottom {
        margin-bottom: 20px;
  }
  &.bottom-double {
        margin-bottom: 50px;
  }
  .right {
        text-align: right;
        align-items: right;
        justify-content: center;
  }
  .label {
    line-height: 20px;
    font-size: 17px;
    color: $grey--7;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
    &.right{
        text-align: right;
    }
    &.mini{
        border-radius: 3px;
        margin-left: 5px;
        padding: 3px 3px;
        color: red;
        border-color: red;
        border: 1px solid red;
        text-align: right;
        font-size: 12px;
        &:focus, &:hover, &:active {
            background-color: red;
            color: white;
            border-color: $grey--2;
            opacity: 0.8;
      }
    }
  }
  .label {
//     line-height: 100%;
    font-size: 17px;
    color: $grey--7;
    font-weight: 500;
//     margin-bottom: 10px;
//     margin-top: 10px;
    &.detail{
        border-radius: 3px;
        margin-left: 20%;
        padding: 3px 3px;
        color: red;
        border-color: red;
        border: 1px solid red;
        text-align: right;
        font-size: 12px;
        &:focus, &:hover, &:active {
            background-color: red;
            color: white;
            border-color: $grey--2;
            opacity: 0.8;
      }
    }
  }
  .sub-label {
    display: inline;
    line-height: 10px;
    font-size: 15px;
    color: $grey--5;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
    margin-left : 0.6%;
    margin-right : 16%;
    &.right {
        text-align: right;
        color : black;
        margin-bottom: 2px;
        margin-top: 2px;
        font-size: 14px;
        margin-right : 0%;
    }
    &.mobile {
        text-align: right;
        color : black;
        margin-bottom: 2px;
        margin-top: 2px;
        font-size: 12px;
        margin-right : 0%;
    }
  }
  .rec-label {
    display: block-inline;
    line-height: 10px;
    font-size: 12px;
    color: $grey--7;
    font-weight: 500;
    margin-top: 15px;
    padding: 4px 4px;
    margin-left: 1px;
    margin-right: 10px;
    margin-bottom: 5px;
    background-color : black;
    border-radius: 3px;
    color: white;
    &.Superb{
        background-color : #FF14EA;
    }
    &.Premium{
        background-color : #3D8FEA;
    }
    &.Gold{
        background-color : #E9A916;
    }
    &.Standard{
        background-color : #AF7E56;
    }
    &.Normal{
        background-color : #006400;
     }
    &.right {
        text-align: right;
        display: block-inline;
        margin-top: 2px;
        margin-bottom: 2px;
    }

  }
  .margin {
    display: block;
    line-height: 1px;
    font-size: 1px;
  }

  + .form-group {
    margin-top: 30px;
  }

}

.form-control {
  height: 47px;
  line-height: 45px;
  font-size: 17px;
  padding: 0 15px;
  border-color: $grey--2;
  white-space: pre-wrap;

  &:focus, &:hover, &:active {
    box-shadow: none;
    outline: none;
    border-color: $grey--2;

    //border-color: inherit;
  }

  &.end{
  color: $grey--5;
  &::after {
  background-image: url(../../public/img/common/ico-check-on.svg);
    }
  }

  &::placeholder {
    color: $grey--5;
  }
}

.form-check {
  padding: 0 10px 10px 20px;
  position: relative;
  display: block;
  height: 24px;
  cursor: pointer;

  &:focus, &:hover, &:active {
        font-weight: 700;
        font-size: 15px;
        color: $primary;
    }

  input {
    padding: 0;
    width: initial;
    height: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    &:checked + span {
      &::after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        background-image: url(../../public/img/common/ico-check-on.svg);
        background-size: cover;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
  }

  span {
    display: block;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    padding-left: 10px;

    &::before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      background-image: url(../../public/img/common/ico-check.svg);
      background-size: cover;
      top: 0;
      left: 0;
    }
  }
}

.check-agree{
  appearance: none;
  color : $white;
  width: 1.7rem;
  height: 1.7rem;
  border-color: $primary;
  border: 3.5px solid $primary;
  border-radius: 0.35rem;
    &:checked {
    border-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: $primary;
  }
  &.depth-1 {
      width: 1.4rem;
      height: 1.4rem;
      margin-left: 40px;
  }
  &.depth-2 {
      width: 1.2rem;
      height: 1.2rem;
      margin-left: 70px;
  }
}

.form-with-btn {
  position: relative;

  .form-control {
    padding: 0 100px 0 15px;
  }

  .btn-send {
    border-color: 30px solid $primary;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: $primary;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    background-color: $white;
    padding: 0 10px 0 10px;

    &:focus, &:hover, &:active {
        background-color: $primary;
        font-weight: 700;
        font-size: 15px;
        color: $white;
    }

    &.colorBlack {
        color:black;
        &:focus, &:hover, &:active {
            background-color: transparent;
            font-weight: 500;
            font-size: 14px;
        }
    }
  }

  .unit {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: $black;
    font-size: 14px;
    font-weight: 500;
  }
  .unit-timer {
    position: absolute;
    right: 240px;
    top: 50%;
    transform: translateY(-50%);
    color: $black;
    font-size: 14px;
    font-weight: 500;
  }
  .unit-check {
    position: absolute;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    right: 128px;
    top: 50%;
    transform: translateY(-50%);
    color: $primary;
    background-color: $white;
    border-color: $primary !important;
    border: 2px solid $primary;
    font-size: 15px;
    font-weight: 500;
    &:hover, &:focus, &:active {
        color: $white;
        background-color: $primary;
    }
}
  .unit-resend {
    position: absolute;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: $grey--7;
    background-color: $white;
    border: 2px solid $grey--7;
    font-size: 15px;
    font-weight: 500;
    &:hover, &:focus, &:active {
        color: $white;
        background-color: $grey--7;
    }
}
}

.form-calendar {
  position: relative;

  .form-control {
    background: url(../../public/img/common/calendar_ico.svg) no-repeat right 15px center /  24px;

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      color: transparent;
      cursor: pointer;
    }

    &::before {
      content: attr(data-placeholder);
      width: 100%;
    }

    &:valid::before, &:valid::before {
      display: none;
    }
  }
}


//input[type=date]::-webkit-datetime-edit-text {
//  color: $grey--5;
//  //-webkit-appearance: none;
//  //display: none;
//}
//
//input[type=date]::-webkit-datetime-edit-month-field{
//  //-webkit-appearance: none;
//  //display: none;
//  color: $grey--5;
//}
//
//input[type=date]::-webkit-datetime-edit-day-field {
//  //-webkit-appearance: none;
//  //display: none;
//  color: $grey--5;
//}
//
//input[type=date]::-webkit-datetime-edit-year-field {
//  //-webkit-appearance: none;
//  //display: none;
//  color: $grey--5;
//}

//input[type='date']::before {
//  content: attr(data-placeholder);
//  width: 100%;
//}
