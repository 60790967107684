.publishing-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}



/* s Main PC */
.main-wrap {
  padding : 60px;
  .nav-tabs {
    justify-content: center;
    border-bottom: 1px solid $grey--1;
    padding-bottom: 20px;

    .nav-item {
      + .nav-item {
        margin-left: 100px;
      }
    }

    .nav-link {
      position: relative;
      border: 0;
      font-size: 20px;
      color: $grey--5;
      font-weight: 700;
      padding: 5px 0;

      &.active {
        color: $black;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          border-bottom: 3px solid;
          background-color: $primary;
        }
      }
    }
  }

  .box {
    margin-top: 60px;
  }

  .button-right {
    text-align: right !important;
  }

  .heading-sm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
    }

    .date {
      font-size: 12px;
      line-height: 14px;
      color: $grey--5;
    }
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
    }

    .text-link {
      font-size: 15px;
      font-weight: 500;
      text-align: right;
      line-height: 18px;
      color: $grey--7;
      border-bottom: 1px solid $grey--7;
    }
  }

  .check-list {
    display: flex;
    justify-content: center;
    align-items: center;

    .item {
      position: relative;
      flex: 1;
      border: 1px solid #f4f3f8;
      border-radius: 10px;
      padding: 30px 20px;
      background-color: $white;

      &:focus, &:active, &:hover {
        border: 2px solid #000000;
        outline: none;
      }
      &:active {
        border: 2px solid #000000;
        outline: none;
        background-color: #f4f3f8;
      }
      &.on {
        border: 2px solid #000000;
        outline: none;
        background-color: #f4f3f8;
      }

      .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: $grey--6;
        margin-bottom: 10px;
      }

      .value {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
      }

      .icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
      }

      + .item {
        margin-left: 20px;
      }
    }
  }

  .banner-slider {
    margin-top: 10px;
    margin-bottom: 55px;

    .banner {
      position: relative;
      width: 100%;
      height: 150px;
      border-radius: 10px;
      color: $white;

      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 31px 50px 0 35px;

        span {
          display: block;
        }
      }

      .sub-text {
        font-size: 12px;
        opacity: .7;
        line-height: 14px;
        margin-bottom: 10px;
      }

      .head-text {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .icon {
        width: 20px;
        height: 20px;
      }

      .img {
        position: absolute;
        width: 230px;
        height: 150px;
        top: 0;
        right: 50px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        opacity: .3;
        background-color: $white;

        &.swiper-pagination-bullet-active {
          opacity: 1;
        }
      }
    }
  }

  .service {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    .item {
      flex: 1;
    }

    .intro {
      position: relative;
      height: 435px;
      border-radius: 10px;
      overflow: hidden;

      .dim {
        position: absolute;
        width: 100%;
        height: 225px;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, #1e1e20 19.15%, rgba(30, 30, 32, 0.00) 88.43%);
        z-index: 3;
      }

      .content {
        background-color: $black;
        padding: 45px 30px 30px;
        color: $white;
      }

      .sub-text {
        font-size: 16px;
        opacity: .7;
        line-height: 19px;
        margin-bottom: 15px;
      }

      .head-text {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 30px;
      }

      .btn-wrap {
        justify-content: flex-start;

        .btn {
          background-color: transparent;
        }
      }

      .img {
        position: absolute;
        width: 100%;
        height: 225px;
        bottom: 0;
        left: 0;

        img {
          aspect-ratio: 540 / 225;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .qna {
      .img {
        width: 100%;
        margin-bottom: 20px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }
      }

      .content {
        > * {
          display: block;
        }

        .txt {
          font-size: 16px;
          line-height: 19px;
          color: #000;
          margin-bottom: 10px;
        }

        .call {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color: #000;
          margin-bottom: 10px;

          br {
            display: none;
          }
        }

        .address {
          font-size: 13px;
          font-weight: 500;
          line-height: 16px;
          color: #000;
          margin-bottom: 15px;
        }

        .info {
          font-size: 12px;
          line-height: 14px;
          color: $grey--6;

          + .info {
            margin-top: 10px;
          }
        }
      }

      .btn-wrap {
        justify-content: flex-start;
        margin-top: 25px;

        .btn {
          border-color: $black;
          min-width: auto;
          padding: 14px 20px;
          color: $black;
          font-weight: 600;

          + .btn {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.reply-box-uniq {
  margin-top: 20px;
  padding: 15px;
  background-color: $grey--0;
  border-radius: 5px;
  height: 250px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .reply-item {
    border: 1px solid $grey--2;
    border-radius: 5px;
    background-color: $white;
    padding: 20px 15px;

    + .reply-item {
      margin-top: 10px;
    }
    &:active {
        font-weight: 700;
        font-size: 15px;
        border-color: $primary;
    }
  }
}
.reply-box {
  padding: 15px;
  background-color: $grey--0;
  border-radius: 5px;
  height: 350px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .reply-item {
    border: 1px solid $grey--2;
    border-radius: 5px;
    background-color: $white;
    padding: 20px 15px;

    + .reply-item {
      margin-top: 10px;
    }

    .content {
      border-bottom: 1px solid $grey--1;
      padding-bottom: 15px;
      margin-bottom: 15px;

      .txt {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .date {
        font-size: 12px;
        line-height: 12px;
        color: $grey--5;
      }

      .user-info {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .name {
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;
          margin-right: 10px;
        }

        .state {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 600;

          .ico {
            margin-right: 5px;
          }
        }

        + .txt {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    .info {
      display: flex;
      justify-content: space-between;

      .item {
        display: flex;
        align-items: center;
      }

      .img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        overflow: hidden;
        margin-right: 10px;

        img {
          aspect-ratio: 1 / 1;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .location {
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        margin-bottom: 5px;
        color: $grey--6;
      }

      .title {
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;
      }

      .reply-box {
        display: flex;
        align-items: center;
      }

      .desc {
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        color: $grey--5;
        margin-right: 10px;
        opacity: 1;
      }

      .price {
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
      }

      .view-reply {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .txt {
          display: flex;
          align-items: center;
          color: $grey--6;
          font-size: 13px;
          font-weight: 500;

          .ico {
            margin-right: 4px;
          }
        }
      }
    }
  }
}

.ui-like {
  display: flex;
  align-items: center;

  .ico {
    margin-right: 4px;
  }

  .btn-like {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: $grey--7;
    font-size: 12px;
    font-weight: 500;

    &.on {
      color: $primary;
    }
  }

  .btn-dislike {
    display: flex;
    align-items: center;
    color: $grey--7;
    font-size: 12px;
    font-weight: 500;

    &.on {
      color: $false;
    }
  }
}
.rec-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px 20px;
  margin-bottom: 35px;

  .item {
    width: 32.1%;

    a {
      display: block;
    }

    .img {
      position: relative;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
        aspect-ratio: 353 / 200;
        object-fit: cover;

        &:hover {
            width: 105%;
        }
      }

      .badge-wrap {
        position: absolute;
        top: 15px;
        left: 15px;
        max-width: 150px;
      }

      .badge-img {
        display: inline-block;
        color: $white;
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        padding: 10px 15px;
        z-index: 2;
        background-color: rgba(30, 30, 32, .7);
        border-radius: 5px;

        + .badge-img {
          margin-top: 5px;
        }

        &.mobile {
          display: none;
        }
      }
    }
  }
}

.card-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 6px 5px;
  margin-bottom: 5px;

  .item {
    width: 17.1%;

    a {
      display: block;
    }

    .img {
      position: relative;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
        aspect-ratio: 353 / 200;
        object-fit: cover;

        &:hover {
            width: 105%;
        }
      }

      .badge-wrap {
        position: absolute;
        top: 15px;
        left: 15px;
        max-width: 150px;
      }

      .badge-img {
        display: inline-block;
        color: $white;
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        padding: 10px 15px;
        z-index: 2;
        background-color: rgba(30, 30, 32, .7);
        border-radius: 5px;

        + .badge-img {
          margin-top: 5px;
        }

        &.mobile {
          display: none;
        }
      }
    }

    .info {
      padding-top: 15px;

      .location {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: $grey--6;
        margin-bottom: 10px;
      }

      .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        margin-bottom: 15px;
      }

      .desc {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: $grey--5;
        margin-bottom: 5px;
      }

      .price {
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        margin-bottom: 10px;
      }

      .badge {
        display: inline-flex;
        align-items: center;
        padding: 10px 15px;
        color: $white;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        border-radius: 50px;

        .ico {
          margin-left: 10px;
        }

        &.badge-primary {
          background-color: $primary;
        }

        &.badge-red {
          background-color: $false;
        }

        &.badge-grey {
          background-color: $grey--5;
        }
      }

      .participants {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  + .btn-wrap {
    .btn {
      &:hover {
        border: 1px solid #d8d8e0;
        color: #68686d;
      }
    }
  }
}

.click-badge {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    color: $white;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    border-radius: 50px;

    .ico {
      margin-left: 10px;
    }

    &.badge-primary {
      background-color: $primary;
    }

    &.badge-red {
      background-color: $false;
    }

    &.badge-grey {
      background-color: $grey--5;
    }
}

/* e Main PC */

/* s Main Mobile */
@media (max-width: 1200px) {
  .main-wrap {
    padding : 10px;
    .nav-tabs {
      position: absolute;
      top: 40px;
      right: 20px;
      border-bottom: 4px solid $grey--2;
      padding: 10px;
      margin-bottom: 40px;
      margin-top: 20px;

      .nav-item + .nav-item {
        margin-left: 20px;
      }

      .nav-link {
        padding: 0;
        font-size: 19px;
        line-height: 18px;

        &.active {
          &::after {
            content: none;
          }
        }
      }
    }

    .box {
      margin-top: 45px;

      &:first-child {
        margin-top: 20px;
      }
    }

    .heading-sm {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 20px;
      margin-bottom: 15px;

      .title {
        margin-bottom: 10px;
      }
    }

    .check-list {
      padding: 0 20px;
      display: block;
      white-space: nowrap;
      overflow-x: auto;

      .item {
        display: inline-block;
        width: 200px;
        flex: none;

        + .item {
          margin-left: 10px;
        }
      }
    }

    .banner-slider {
      margin-top: 22px;
      padding: 0 20px;

      .banner {
        height: 233px;

        a {
          padding: 20px 20px 0;
        }

        .icon {
          display: none;
        }

        .img {
          right: auto;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          top: auto;
        }
      }
    }

    .heading {
      padding: 0 20px;
      margin-bottom: 15px;

      .title {
        width: calc(100% - 40px);
        word-break: keep-all;
        font-size: 18px;
        line-height: 21px;
      }

      .text-link {
        font-size: 0;
        width: 20px;
        height: 20px;
        background-image: url(../../public/img/common/view_all_mobile_ico.svg);
        background-size: cover;
        border-bottom: 0;
      }
    }

    .service {
      flex-direction: column;
      gap: 0;
      padding: 0 20px;

      .intro {
        height: 430px;
        margin-bottom: 45px;

        .content {
          padding: 35px 20px;

          .sub-text {
            font-size: 13px;
            line-height: 16px;
            margin-bottom: 10px;
          }

          .head-text {
            width: 200px;
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 20px;
          }
        }

        .dim {
          background: linear-gradient(180deg, #1e1e20 10.87%, rgba(30, 30, 32, 0.00) 97.37%);
        }

        .btn-wrap {
          .btn {
            padding: 14px 20px;
          }
        }
      }

      .qna {
        position: relative;
        margin-top: 45px;

        .img {
          img {
            height: 120px;
            aspect-ratio: 335 / 120;
          }
        }

        .content {
          .txt {
            font-size: 13px;
            line-height: 16px;

          }

          .call {
            font-size: 18px;
            line-height: 21px;

            br {
              display: block;
            }
          }

          .address {
            margin-bottom: 10px;
          }
        }

        .btn-wrap {
          margin-top: 20px;
        }

        &::before {
          content: '';
          position: absolute;
          width: calc(100% + 40px);
          height: 1px;
          background-color: $grey--1;
          top: -45px;
          left: -20px;
        }
      }
    }
  }

  .reply-box {
    width: calc(100% - 40px);
    margin: 0 auto;
    height: 250px;

    .reply-item {
      .info {
        flex-direction: column;
        justify-content: flex-start;

        .item {
          width: 100%;

          + .item {
            margin-top: 12px;
          }
        }
      }
    }
  }

  .card-list {
    display: block;
    gap: 0;
    margin-bottom: 0;
    padding: 0 20px;
    white-space: nowrap;
    overflow-x: auto;

    .item {
      display: inline-block;
      width: 160px;

      a {
        display: flex;
        flex-direction: column;
      }

      .img {
        border-radius: 5px;

        img {
          aspect-ratio: 160 / 120;
        }

        .badge-wrap {
          max-width: 88px;
          white-space: break-spaces;
          top: 10px;
          left: 10px;
        }

        .badge-img {
          display: none;
          padding: 5px 10px 5px 25px;
          background-image: url(../../public/img/common/timer_ico.svg);
          background-size: 12px;
          background-repeat: no-repeat;
          background-position: left 10px center;

          &.time {
            background-image: url(../../public/img/common/timer_ico.svg);
          }

          &.participants {
            background-size: 12px 10px;
            background-image: url(../../public/img/common/people_ico.svg);
          }

          &.mobile {
            display: inline-block;
          }
        }
      }

      .info {
        display: flex;
        flex-direction: column;

        .location {
          font-size: 12px;
          line-height: 12px;
          white-space: pre-wrap;
        }

        .title {
          font-size: 13px;
          line-height: 16px;
          white-space: pre-wrap;
        }

        .price {
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 5px;
        }

        .desc {
          font-size: 12px;
          line-height: 12px;
          order: 1;
          margin-bottom: 15px;
        }

        .badge {
          font-size: 12px;
          line-height: 14px;
          order: 2;
          place-self: flex-start;
        }
      }

      + .item {
        margin-left: 10px;
      }
    }

    + .btn-wrap {
      display: none;
    }
  }
}

/* e Main Mobile */

/* s 검색 결과 PC */
.search-result-wrap {
  .heading-result {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
  }

  .search-box {
    margin-bottom: 15px;
    z-index: 19;

    .s-input {
      width: 80%;
      padding-left: 20px;
    }
  }

  hr {
    border-top: 1px solid $grey--1;
    opacity: 1;
    margin: 35px 0;
  }

  .box {
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;

      .title {
        font-size: 20px;
        font-weight: 700;

        .unit {
          font-size: 15px;
          font-weight: 600;
          margin-left: 10px;
        }
      }

      .sorting {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: $grey--7;
        font-weight: 500;
        cursor: pointer;
        position: relative;

        .ico {
          margin-left: 5px;
        }
      }

      .sorting-menu {
        position: absolute;
        top: 28px;
        right: 0;
        width: 180px;
        border-radius: 5px;
        background-color: $white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
        z-index: 5;
        padding: 15px;
        display: none;

        &.show {
          display: block;
        }

        .item {
          button {
            display: block;
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;
            color: $grey--5;
          }

          &.on {
            button {
              color: $black;
            }
          }

          + .item {
            margin-top: 15px;
          }
        }
      }
    }
  }

  + .popup-bottom {
    + .backdrop {
      display: none;
    }
  }
}

/* e 검색 결과 PC */

/* s 검색 결과 Mobile */
@media (max-width: 1200px) {
  .search-result-wrap {
    .heading-result {
      display: none;
    }

    .search-box {
      width: calc(100% - 40px);
      margin: 0 auto 15px;
    }

    hr {
      margin: 25px 0;
    }

    .box {
      .heading {
        padding: 0 20px;

        .title {
          font-size: 18px;
        }
      }

      .card-list {
        margin-bottom: 35px;
        display: flex;
        gap: 20px;

        .item {
          width: calc(50% - 20px);
        }
      }
    }

    + .popup-bottom {
      + .backdrop {
        &.show {
          display: block;
        }
      }
    }
  }
}

/* e 검색 결과 Mobile */

.filter-box {
  border-radius: 10px;
  border: 1px solid $grey--2;
  background-color: $grey--0;
  padding: 18.5px 20px;

  .filter-head {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 14px;
      font-weight: bold;
      //line-height: 20px;
    }

    .info {
      display: flex;
      align-items: center;

      .state {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $primary;
        font-weight: 600;

        &::after {
          content: '';
          display: inline-block;
          width: 3px;
          height: 3px;
          border-radius: 100%;
          background-color: $grey--4;
          margin: 0 10px;
        }
      }

      .state2 {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;

        span {
          margin-right: 5px;
        }
      }

      .state3 {
        display: none;
        //display: flex;
        align-items: center;
        font-size: 14px;
        color: $grey--6;
        font-weight: 500;

        .ico {
          margin-right: 5px;
        }
      }
    }
  }

  .filter-body {
    display: none;
    padding-top: 2px;

    .type-list {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;

      &::after {
        content: '';
        position: absolute;
        left: -20px;
        bottom: -20px;
        width: calc(100% - 40px);
        height: 1px;
        background-color: $grey--1;
      }

      .item {
        margin-top: 20px;
        width: 100%;

        &.item-half {
          width: calc(50% - 10px);

          + .item-half {
            margin-left: 20px;
          }
        }

        .label {
          font-size: 14px;
          font-weight: 500;
          color: $grey--6;
        }

        .list {
          background-color: $grey--0;
          border-radius: 5px;
          padding: 10px;
          white-space: nowrap;

          .swiper-slide {
            display: inline-block;
            width: auto;
            height: 37px;
            vertical-align: middle;

            button {
              display: inline-block;
              background-color: $white;
              border: 1px solid $grey--2;
              border-radius: 5px;
              font-size: 14px;
              font-weight: 500;
              color: $grey--5;
              line-height: 35px;
            }

            &.on {
              button {
                border-color: $black;
                color: $black;
              }
            }


            + .list-item {
              margin-left: 10px;
            }

            button {
              padding: 0 15px;
            }
          }
        }
      }
    }

    .range-list {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;

      &::after {
        content: '';
        position: absolute;
        left: -20px;
        bottom: -20px;
        width: calc(100% - 40px);
        height: 1px;
        background-color: $grey--1;
      }

      .item {
        margin-top: 20px;
        width: 100%;

        &.item-half {
          width: calc(50% - 10px);

          + .item-half {
            margin-left: 20px;
          }
        }

        > .label {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 500;
          color: $grey--6;
        }
      }

      .multi-range-slider {
        padding: 10px 10px 20px;

        .bar {
          height: 6px;
        }

        .label {
          font-size: 12px;
          color: $grey--6;
        }

        .ruler {
          display: none;
        }

        .caption {
          display: none;
        }
      }
    }
  }

  .filter-footer {
    display: none;
    //display: flex;
    justify-content: center;

    .btn-black + .btn-black {
      margin-left: 20px;
    }
  }

  &.show {
    background-color: $white;

    .filter-head {
      .info {
        .state {
          display: none;
        }

        .state2 {
          display: none;
        }

        .state3 {
          display: flex;
        }
      }
    }

    .filter-body {
      display: block;
    }

    .filter-footer {
      display: flex;
    }
  }
}

@media (max-width: 1200px) {
  .filter-box {
    width: calc(100% - 40px);
    margin: 0 auto;

    .filter-head {
      .title {
        font-size: 13px;
      }

      .info {
        .state, .state2, .state3 {
          font-size: 13px;
        }
      }
    }
  }
}

/* s 입찰 참여 */
.start-wrap {
  padding-top: 60px;

  .top-heading {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
  }

  .card-list {
    margin-bottom: 60px;

    .item {
      width: 100%;
      margin-bottom: 60px;

      .img {
        width: 300px;
        height: 200px;
        margin: 0 auto 30px;

        img {
          aspect-ratio: 300 / 200;
        }
      }

      .info {
        padding-top: 0;
        margin-bottom: 30px;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;

          &:first-child {
            margin-bottom: 25px;
          }

          &:nth-child(2) {
            margin-bottom: 10px;
          }

          .font-1 {
            font-size: 12px;
            color: $grey--5;
            line-height: 14px;
            margin-right: 5px;
          }

          .font-2 {
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
          }

          .font-3 {
            color: $grey--6;
            font-size: 13px;
            line-height: 16px;
            font-weight: 500;
          }

          .font-4 {
            font-size: 20px;
            font-weight: bold;
            line-height: 24px;
          }
        }
      }
    }

    .btn-wrap {
      .btn {
        flex: none;
        height: 61px;
        line-height: 59px;
        font-size: 18px;
        min-width: 223px;
      }
    }
  }

  .info-row {
    display: flex;

    + .info-row {
      margin-top: 10px;
    }

    .card-item {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      border: 1px solid $grey--2;
      background: $white;
      padding: 20px 15px;

      &.card-item-point {
        background-color: $primary;

        .title {
          color: $white;
        }

        .desc {
          color: $white;
        }
      }

      + .card-item {
        margin-left: 10px;
      }

      .title {
        font-size: 12px;
        font-weight: 500;
        color: $grey--6;
        line-height: 17px;
      }

      .desc {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 14px;
        font-weight: 600;

        .added {
          font-size: 14px;
          color: $grey--6;
        }
        .state {
          font-size: 12px;
          color: $false;
        }
        .state-green {
          font-size: 12px;
          color: green;
        }
      }
    }
  }

  .bidding-details {
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;

      .title {
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
      }

      .desc {
        font-size: 12px;
        color: $grey--6
      }
    }

    .box {
      background-color: $grey--0;

      .table-wrap {
        position: relative;
        padding-top: 20px;

        .table-row {
          display: flex;

          .item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
          }
        }

        .table-head {
          position: absolute;
          top: 20px;
          left: 0;
          width: 100%;
          border-radius: 5px;
          overflow: hidden;
          background-color: $grey--2;

          .item {
            color: $grey--6;
            font-size: 13px;
            font-weight: 500;
            text-align: center;
            line-height: 16px;
            padding: 10px;
          }
        }

        .table-body {
          min-height: 304px;
          max-height: 304px;
          overflow-y: auto;
          padding-top: 41px;
          padding-bottom: 5px;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */

          .table-row {
            border-radius: 5px;
            border: 1px solid $grey--2;
            background-color: $white;

            + .table-row {
              margin-top: 5px;
            }
          }

          .item {
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            padding: 10px;

            .font-sm {
              display: inline-block;
              margin-left: 3px;
              font-size: 12px;
              font-weight: 400;
              color: $grey--6;
            }
          }

          &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .start-wrap {
    padding-top: 45px;

    .top-heading {
      margin-bottom: 20px;
    }

    .card-list {
      margin-bottom: 45px;

      .item {
        margin-bottom: 0;

        .img {
          width: 100%;
          height: auto;
          aspect-ratio: 335 / 220;

          img {
            width: 100%;
            height: 100%;
            aspect-ratio: 335 / 220;
          }

          .badge-wrap {
            max-width: 200px;

            .badge-img {
              display: inline-block;
              background-image: none;
              padding: 10px 15px;
            }
          }
        }

        .info {
          .item {
            &:first-child {
              margin-bottom: 15px;
            }

            &:nth-child(2) {
              margin: 0 0 10px;
            }

            &:nth-child(3) {
              margin: 0;
            }
          }
        }
      }
    }

    .info-row {
      flex-direction: column;

      .card-item {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;

        .title {
          margin-bottom: 5px;
        }

        .desc {
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }

        + .card-item {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }

    .btn-wrap {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 10;

      .btn {
        width: 100%;
        border-radius: 0;
      }
    }

    .bidding-details {
      .heading {
        padding: 0 20px;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;

        .title {
          margin-bottom: 10px;
        }
      }

      .box {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;

        .table-wrap {
          padding: 0 20px 0 0;
          overflow-x: auto;

          .table-head {
            position: static;
            width: 630px;
            top: 0;
          }

          .table-body {
            min-height: 284px;
            max-height: 284px;
            width: 630px;
            padding-top: 5px;

            .item {
              &:first-child {
                display: flex;
                flex-direction: column;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}

/* e 입찰 참여 */

/* s 매물 상세 */
.detail-wrap {
  //border-top: 1px solid $grey--1;
  padding-top: 60px;

  .info-row {
    display: flex;
    margin-bottom: 60px;

    .col-slider {
      position: relative;
      width: 600px;
      margin-right: 30px;

      .view-slider {
        margin-bottom: 20px;

        .swiper-slide {
          position: relative;
          aspect-ratio: 600 / 400;
          border-radius: 10px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .badge-wrap {
            position: absolute;
            top: 15px;
            left: 15px;
            max-width: 150px;
          }

          .badge {
            display: inline-block;
            color: $white;
            font-size: 12px;
            line-height: 14px;
            font-weight: 600;
            padding: 10px 15px;
            z-index: 2;
            background-color: rgba(30, 30, 32, .7);
            border-radius: 5px;

            + .badge {
              margin-top: 5px;
            }
          }
        }
      }

      .thumbnail-slider {
        width: calc(140px * 3 + 15px * 2);

        .swiper-slide {
          width: 140px;
          height: 80px;
          aspect-ratio: 140 / 80;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .btn-slide-prev, .btn-slide-next {
        position: absolute;
        top: 448px;
        width: 24px;
        height: 24px;
      }

      .btn-slide-prev {
        left: 36px;
      }

      .btn-slide-next {
        right: 36px;
      }
    }

    .col-info {
      width: calc(100% - 600px - 30px);

      .number {
        display: flex;
        align-items: center;
        line-height: 14px;
        font-size: 12px;
        margin-bottom: 25px;
        font-weight: 500;

        .label {
          color: $grey--5;
          margin-right: 5px;
        }
      }

      .address {
        color: $grey--6;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 10px;
      }

      > .title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .badge-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;

        .badge {
          color: $primary;
          border: 1px solid $primary;
          border-radius: 50px;
          padding: 10px 15px;
          line-height: 16px;
          font-size: 13px;
          font-weight: 600;
        }
      }

      .border-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;

        .item {
          width: calc(50% - 5px);
          border: 1px solid $grey--2;
          background-color: $white;
          padding: 15px;
          border-radius: 10px;

          .label {
            font-size: 12px;
            line-height: 14px;
            font-weight: 500;
            color: $grey--6;
          }

          .value {
            font-size: 14px;
            line-height: 17px;
            font-weight: 600;
            text-align: right;
            margin-top: 5px;
          }

          * {
            display: block;
          }

          .desc {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 12px;
            line-height: 14px;
            margin-top: 5px;

            .ico {
              margin-left: 5px;
            }
          }

          &.timer {
            width: 100%;
            background-color: rgba(30, 30, 32, .8);
            border: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .label {
              color: rgba(255, 255, 255, .7);
            }

            .value {
              margin-top: 0;
            }
          }
        }
      }

      .info-last {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .label {
          color: $grey--6;
          font-size: 12px;
          font-weight: 500;
        }

        .value {
          text-align: right;

          * {
            display: block;
          }

          .total {
            font-size: 18px;
            line-height: 27px;
            font-weight: 600;
          }

          .desc {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 12px;
            font-weight: 600;
            line-height: 14px;
            margin-top: 5px;

            .ico {
              margin-left: 5px;
            }
          }
        }
      }

      .btn-wrap {
        gap: 10px;
      }
    }
  }

  .tab-line {
    margin: 0;
    border-top: 1px solid $grey--1;
    opacity: 1;
    transform: translateY(44px);
  }

  .line {
    margin: 60px 0;
    border-top: 1px solid $grey--1;
    opacity: 1;
  }

  .tab {
    position: relative;
    border-bottom: 0;
    margin-bottom: 61px;

    .nav-item + .nav-item {
      margin-left: 25px;
    }

    .nav-link {
      font-size: 16px;
      line-height: 19px;
      color: $grey--5;
      margin: 0;
      border: 0;
      padding: 0;

      &:hover, &:active, &:focus {
        border: 0;
        outline: none;
        box-shadow: none;
      }

      &.active {
        border: 0;
        font-weight: 600;
        color: $black;
      }
    }
  }

  .tab-sub-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 25px;
  }

  .caution {
    color: $grey--6;
    font-size: 12px;
    line-height: 12px;
    margin-top: 7px;
    margin-bottom: 25px;
  }

  .ai-report {
    .box-row {
      display: flex;
      gap: 20px;

      + .tab-sub-title {
        margin-top: 60px;
        margin-bottom: 25px;
      }
    }

    .box {
      flex: 1;
      border-radius: 5px;
      background-color: $grey--0;
      padding: 15px;

      .content {
        border-radius: 5px;
        border: 1px solid $grey--2;
        background-color: $white;
        padding: 20px 15px;
        height: 100%;

        .sub-title {
          font-size: 13px;
          font-weight: 600;
          line-height: 16px;
          margin-bottom: 15px;
        }

        .dot-list {
          display: flex;
          align-items: center;
          padding-bottom: 15px;
          border-bottom: 1px solid $grey--1;
          margin-bottom: 15px;

          &.false {
            .item {
              &.on {
                background-color: $false;
              }
            }
          }

          .item {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            background-color: $grey--3;

            + .item {
              margin-left: 5px;
            }

            &.on {
              background-color: $primary;
            }
          }
        }

        .txt-list {
          .item {
            position: relative;
            font-size: 14px;
            line-height: 17px;
            padding-left: 17px;
            color: #000;

            &::before {
              content: '-';
              font-size: 14px;
              height: 14px;
              width: 7px;
              position: absolute;
              left: 0;
              top: 0;
              color: #000;
            }

            + .item {
              margin-top: 17px;
            }
          }

          .sub-item {
            position: relative;
            font-size: 14px;
            line-height: 17px;
            padding-left: 40px;
            color: #000;

            &::before {
              content: '→';
              font-size: 13px;
              height: 14px;
              width: 7px;
              position: absolute;
              padding-left: 20px;
              left: 0;
              top: 0;
              color: #000;
            }
            + .item {
              margin-top: 17px;
            }
          }
        }

        .txt {
          font-size: 14px;
          line-height: 20px;
          color: #000;
        }

        .txt-14 {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;

          + .txt-14 {
            margin-top: 10px;
          }

          + .txt-13 {
            margin-top: 5px;
          }
        }

        .txt-13 {
          font-size: 13px;
          line-height: 20px;
          color: $grey--7;

          + .txt-14 {
            margin-top: 10px;
          }
        }

        .btn-wrap {
          border-top: 1px solid $grey--1;
          padding-top: 20px;
          margin-top: 20px;
        }
      }
    }
  }

  .live-reply-list {
    .reply-box {
      height: 480px;
    }
  }

  .btn-refresh {
    margin-left: 15px;

    .ico {
      transform: translateY(2px);
      margin-right: 5px;
      &:focus, &:hover, &:active {
        transform: translateY(4px);
      }
    }

    span {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      color: $grey--6;
      transform: translateY(-2px);
      &:focus, &:hover, &:active {
        font-weight: 700;
      }
    }
  }

  .sorting {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: $grey--7;
    font-weight: 500;

    .ico {
      margin-left: 5px;
    }
  }

  .history-info {
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;

      .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
      }

      .desc {
        display: flex;
        align-items: center;

        .item {
          display: flex;
          align-items: center;

          + .item {
            margin-left: 35px;
          }
        }

        .label {
          color: $grey--5;
          font-size: 12px;
          font-weight: 500;
          margin-right: 5px;
        }

        .val {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
        }
      }
    }

    .history-item + .history-item {
      margin-top: 60px;
    }

    .border-box {
      .item-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;

        + .item-row {
          margin-top: 10px;
        }

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid $grey--2;
          border-radius: 10px;
          padding: 20px 15px;

        }

        .label {
          color: $grey--5;
          font-size: 12px;
          font-weight: 500;
        }

        .val {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: 14px;
          line-height: 17px;
          font-weight: 600;

          > * + * {
            margin-top: 10px;
          }
        }

        .item {
          flex-basis: calc(25% - 10px);

          &.item-half {
            flex-basis: calc(50% - 5px);
          }

          &.item-full {
            flex-basis: 100%;
          }
        }
      }
    }

    .table-row {
      display: flex;

      .chart-area {
        width: 534px;
        //height: 237px;
        //background-color: pink;
        margin-right: 30px;

        .chart-sort {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          .btn-top {
            color: $grey--5;
            font-size: 16px;
            font-weight: 600;

            &.on {
              color: $black;
            }

            + .btn-top {
              margin-left: 25px;
            }
          }
        }

        .chart-bottom {
          display: flex;
          align-items: center;
          padding-left: 34px;

          * {
            font-size: 12px;
            font-weight: 500;

            + * {
              margin-left: 5px;
            }
          }
        }
      }

      .info-list-wrap {
        width: calc(100% - 534px);
        padding-top: 45px;
      }
    }

    .info-list {
      //width: calc(100% - 534px - 30px);

      .scroll-item {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .list-head {
        display: flex;
        border-radius: 5px;
        overflow: hidden;

        .item {
          flex-basis: 25%;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $grey--5;
          font-size: 13px;
          font-weight: 500;
          background-color: $grey--1;
        }
      }

      .list-body {
        display: flex;
        border-radius: 5px;
        border: 1px solid $grey--2;

        .item {
          flex-basis: 25%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          font-weight: 500;
          white-space: pre-line;
        }
      }
    }

    .list-foot {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 15px;

      .btn-nav {
        display: flex;
        align-items: center;

        > * {
          color: $grey--6;
          font-size: 14px;
          font-weight: 500;
        }

        > * + * {
          margin-left: 5px;
        }

        + .btn-nav {
          margin-left: 30px;
        }
      }
    }


    .table-box {
      .info-list {
        width: 100%;

        .list-body-box {
          display: flex;
          padding: 10px;
          border: 1px solid $grey--2;
          border-radius: 5px;
          justify-content: center;
          align-items: center;

          &.bg-red {
            border: 1px solid #fac0cc;
            background: #ffe7ec;

            .list-body {
              border: 1px solid #fac0cc;
            }
          }

          &.bg-blue {
            border: 1px solid #b0c4ff;
            background: #e7edff;

            .list-body {
              border: 1px solid #b0c4ff;

            }
          }

          .item {
            text-align: center;
            font-size: 13px;
            font-weight: 500;
          }

          .list-body {

            .item {
              flex-basis: 50%;
              // todo:
              &.off {
                text-decoration: line-through;
                color: $grey--6;
              }
            }

            + .list-body {
              margin-top: 5px;
            }
          }

          .list-lg-item {
            &.per-25 {
              flex-basis: 25%;
            }

            &.per-50 {
              flex-basis: 50%;
            }
            &.per-100 {
              flex-basis: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .detail-wrap {
    padding-top: 39px;

    .inner {
      padding: 0;

      &:last-child {
        .tab-sub-title {
          padding: 0 20px;
        }
      }
    }

    .view-slider {
      width: calc(100% - 40px);
      margin: 0 auto;

      .swiper-slide {

      }
    }

    .info-row {
      flex-direction: column;
      margin-bottom: 45px;

      .col-slider {
        max-width: 600px;
        width: 100%;
        margin: 0 auto 30px;

        .view-slider {
          .swiper-slide {
            .badge-wrap {
              max-width: 88px;
              white-space: break-spaces;
              top: 10px;
              left: 10px;
            }

            .badge-img {
              display: none;
              padding: 5px 10px 5px 25px;
              background-image: url(../../public/img/common/timer_ico.svg);
              background-size: 12px;
              background-repeat: no-repeat;
              background-position: left 10px center;

              &.time {
                background-image: url(../../public/img/common/timer_ico.svg);
              }

              &.participants {
                background-size: 12px 10px;
                background-image: url(../../public/img/common/people_ico.svg);
              }

              &.mobile {
                display: inline-block;
              }
            }
          }
        }

        .btn-slide-next, .btn-slide-prev {
          display: none;
        }

        .btn-slide-prev {
          left: 0;
        }

        .btn-slide-next {
          right: 0;
        }

        .thumbnail-slider {
          width: 100%;

          .swiper-slide {
            height: auto;
            aspect-ratio: 140 / 80;
            opacity: .4;

            &.swiper-slide-active {
              opacity: 1;
            }
          }
        }
      }

      .col-info {
        width: 100%;
        padding: 0 20px;

        .number {
          margin-bottom: 10px;
        }

        .border-list {
          gap: 10px;

          .item {
            width: 100%;
          }
        }

        .btn-wrap {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          gap: 0;
          z-index: 5;

          .btn {
            flex: 1;
            border-radius: 0;

            &.btn-black-border {
              border-color: $grey--2;
            }
          }
        }

        .info-last {
          margin-bottom: 0;
        }
      }
    }

    .tab {
      display: block;
      white-space: nowrap;
      overflow-x: auto;
      padding: 0 20px;
      margin-bottom: 52px;

      .nav-item {
        flex: none;
        display: inline-block;
      }
    }

    .tab-sub-title {
      font-size: 16px;
      line-height: 19px;

      .caution {
        margin-bottom: 15px;
      }
    }

    .ai-report {
      padding: 0 20px;

      .box-row {
        flex-direction: column;

        + .tab-sub-title {
          margin-top: 45px;
          margin-bottom: 15px;
        }
      }

      .box {
        .content {
          .txt-list {
            .item {
              font-size: 12px;
              line-height: 14px;

              + .item {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }

    .line {
      margin: 45px 0;
    }

    .live-reply-list {
      .tab-sub-title {
        padding: 0 20px;
        margin-bottom: 15px;
        align-items: center;
      }
    }

    .history-info {
      //padding: 0 20px;

      .heading {
        margin-bottom: 15px;

        &.with-desc {
          flex-direction: column;
          align-items: flex-start;

          .title {
            margin-bottom: 10px;
          }
        }

        .title {
          font-size: 16px;
          line-height: 19px;
        }

        .desc {
          flex-direction: column;
          align-items: flex-start;

          .item {
            + .item {
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }
      }

      .border-box {
        //overflow-x: auto;
        //white-space: nowrap;

        .item-row {
          display: block;
          flex-direction: column;
          flex: none;
          overflow-x: auto;
          white-space: nowrap;

          .item {
            display: inline-block;
            width: auto;
            min-width: 300px;
            //max-width: 300px;

            + .item {
              margin-left: 10px;
            }
          }
        }
      }

      .history-item {
        padding: 0 0 0 20px;

        .item-row {
          padding-right: 20px;
        }

        + .history-item {
          margin-top: 45px;
        }
      }

      .table-row {
        flex-direction: column;

        .chart-area {
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;

          .chart {
            height: 300px;
            overflow-x: auto;
            padding-right: 20px;

            .chart-inner {
              min-width: 534px;
              width: 100%;
            }
          }

          .chart-bottom {
            margin-top: 10px;
          }
        }

        .info-list-wrap {
          width: 100%;
          padding-top: 0;
        }
      }

      .info-list {
        width: 100%;
        overflow-x: auto;

        .scroll-item {
          display: block;
          min-width: calc(134px * 4);
          padding-right: 20px;
        }

        .list-body-box {
          + .list-body-box {
            margin-top: 5px;
          }
        }

        .list-head {
          margin-bottom: 5px;
        }

        .list-body {
          + .list-body {
            margin-top: 5px;
          }
        }
      }

      .table-row {
        .chart-area {
          margin-bottom: 30px;
        }
      }
    }
  }
}
/* e 매물 상세 */

/* s 약관 */
.title-agree{
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
      font-size: 16px !important;
      font-weight: 500;
      color: $black;
      margin-left: 10px;
      vertical-align: top;
    }

  .ico {
      color: $black;
    }
}
.content-agree {
  margin-left: 40px;
  margin-top: 0;
  background-color: $grey--0;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding: 15px;
  line-height: 17px;
  min-height: 154px;
  max-height: 154px;
  overflow-y: auto;
}
/* e 약관 */

/* s 로그인 + 회원가입 + 비밀번호/ID 찾기 */
.login-wrap {
  padding-top: 60px;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 24px;
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 30px;
    text-align: center;

    .desc {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: $grey--6;
      margin-top: 15px;
    }
  }

  .form-box {
    max-width: 500px;
    margin: 0 auto;
  }

  .form-group {
    .content {
      background-color: $grey--0;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      padding: 15px;
      line-height: 17px;
      min-height: 154px;
      overflow-y: auto;
    }
    .tip {
      margin-top: 10px;
      font-size: 15px;
      font-weight: 700;
      line-height: 14px;
      color: $grey--3;
    }
  }

  .btn-wrap {
    margin-top: 30px;

    .btn {
      flex: none;
      display: inline-block;
      min-width: auto;
      padding: 0 80px;
      font-size: 18px;
      height: 61px;
      font-weight: 600;
    }
  }

  .find {
    margin-top: 30px;

    .item {
      display: flex;
      justify-content: flex-end;

      + .item {
        margin-top: 15px;
      }

      .txt {
        font-size: 14px;
        line-height: 17px;
        color: $grey--7;
        margin-right: 10px;
      }
    }
  }

  .txt-link {
    font-size: 16px;
    line-height: 17px;
    font-weight: 500;
    text-decoration: underline;
  }

  .start-sns {
    text-align: center;
    margin-top: 60px;
    padding-top: 60px;
    border-top: 1px solid $grey--1;

    .sub-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
      margin-bottom: 30px;
    }

    .links {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn-link {
        width: 60px;
        height: 60px;

        + .btn-link {
          margin-left: 30px;
        }
      }
    }
  }

  .sign-in {
    text-align: center;

    .btn-wrap {
      margin-top: 60px;
      margin-bottom: 30px;
    }
  }

  .find-account {
    text-align: center;

    .btn-wrap {
      margin-top: 60px;
    }

    .info {
      color: $grey--7;
      font-size: 14px;
      line-height: 17px;
      margin-top: 30px;
    }
  }

  hr {
    margin: 60px 0;
    opacity: 1;
    border-top: 1px solid $grey--1;
  }
}

@media (max-width: 1200px) {
  .login-wrap {
    padding-top: 45px;

    .title {
      margin-bottom: 20px;
    }

    .inner {
      padding: 0 20px;
    }

    .find {
      .item {
        flex-direction: column;
        align-items: center;

        + .item {
          margin-top: 30px;
        }

        .txt {
          margin-right: 0;
        }

        .txt-link {
          margin-top: 16px;
        }
      }
    }

    .form-group {
      + .form-group {
        margin-top: 20px;
      }
    }

    .start-sns {
      padding-top: 30px;
      margin-top: 30px;

      .sub-title {
        margin-bottom: 20px;
      }
    }

    .sign-in {
      .btn-wrap {
        margin-top: 30px;
      }
    }

    .btn-wrap {
      .btn {
        flex: 1;
      }
    }

    .form-box {
      width: 100%;
      max-width: none;
    }

    .find-account {
      .btn-wrap {
        margin-top: 30px;
      }
    }

    hr {
      margin: 45px 0;
    }
  }
}

/* e 로그인 + 회원가입 + 비밀번호/ID 찾기 */

/* s 모니터링 */
.monitor-wrap {
  padding-top: 60px;

  .inner > .title {
    display: flex;
    justify-content: center;
    line-height: 24px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;

    span {
      margin-left: 10px;
    }
  }

  .btn-wrap {
    margin-top: 30px;
  }
}

@media (max-width: 1200px) {
  .monitor-wrap {
    padding-top: 45px;

    .btn-sort-list {
      margin-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .inner {
      > .title {
        margin-bottom: 20px;
        padding: 0 20px;
      }
    }

    .list-box {
      width: calc(100% - 40px);
      margin: 0 auto;
    }
  }
}

.btn-sort-list-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1200px) {
  .btn-sort-list-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.btn-sort-list {
  margin-bottom: 30px;
}

.list-box {
  padding: 15px;
  background-color: $grey--0;
  border-radius: 5px;
  min-height: 665px;

  > .item {
    border: 1px solid $grey--2;
    border-radius: 5px;
    background-color: $white;
    padding: 15px;

    &.fail {
      border-color: $false;
    }

    &.success {
      border-color: $primary;
    }

    + .item {
      margin-top: 10px;
    }

    &.register {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      border: 1px dashed $grey--4;
      background-color: $grey--1;
      padding: 30px 0 40px;

      p {
        color: $grey--6;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }

    .content {
      .content-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        .state {
          font-size: 12px;
          line-height: 14px;
          font-weight: 500;
        }
      }

      .txt {
        font-size: 13px;
        line-height: 20px;
        font-weight: 600;

        &.txt-lg {
          font-size: 16px;
          line-height: 19px;
        }
      }

      .date {
        font-size: 12px;
        line-height: 12px;
        color: $grey--5;
      }

      .user-info {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .name {
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;
          margin-right: 10px;
        }

        .state {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 600;

          .ico {
            margin-right: 5px;
          }
        }

        + .txt {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    .info {
      display: flex;
      justify-content: space-between;
      border-radius: 5px;
      padding: 10px;
      border: 1px solid $grey--1;
      margin-top: 15px;

      .item {
        display: flex;
        align-items: center;
      }

      .img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        overflow: hidden;
        margin-right: 10px;
        position: relative;

        &.img-dim {
          &::before {
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .3);
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        img {
          aspect-ratio: 1 / 1;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .location {
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        margin-bottom: 5px;
        color: $grey--6;
      }

      .title {
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;
      }

      .desc {
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        color: $grey--5;
        margin-right: 10px;
      }

      .price {
        font-size: 13px;
        line-height: 16px;
        font-weight: 600;
      }

      .badge-price {
        margin-top: 10px;
      }

      &.info-lg {
        border: 0;
        border-bottom: 1px solid $grey--1;
        padding: 0 0 15px;
        border-radius: 0;
        margin-top: 0;

        .img {
          position: relative;
          width: 200px;
          height: 100px;
          border-radius: 10px;
          margin-right: 20px;

          .badge-wrap {
            max-width: 150px;
            display: block;
            position: absolute;
            top: 10px;
            left: 10px;

            .badge {
              padding: 10px 15px;
              background-color: rgba(0, 162, 173, .7);
              border-radius: 10px;
              font-weight: 600;
              line-height: 14px;
              font-size: 12px;
              color: $white;
              display: inline-block;

              + .badge {
                margin-top: 5px;
              }

              &.badge-black {
                background-color: rgba(30, 30, 32, .7);
              }

              &.badge-red {
                background-color: rgba(255, 66, 111, .7);
              }
            }

            .badge-img {
              font-size: 12px;
              line-height: 14px;
              font-weight: 600;
              z-index: 2;
              background-color: rgba(30, 30, 32, 0.7);
              border-radius: 5px;
              display: none;
              padding: 5px 10px 5px 25px;
              background-image: url(../../public/img/common/timer_ico.svg);
              background-size: 12px;
              background-repeat: no-repeat;
              background-position: left 10px center;
              color: $white;

              &.time {
                background-image: url(../../public/img/common/timer_ico.svg);
              }

              &.participants {
                background-size: 12px 10px;
                background-image: url(../../public/img/common/people_ico.svg);
              }

              + .badge-img {
                margin-top: 5px;
              }

              &.mobile {
                display: none;
              }
            }
          }

          img {
            aspect-ratio: 200 / 100;
          }
        }

        .item:nth-child(2) {
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;

          .desc {
            margin-right: 0;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 5px;
          }

          .price {
            font-size: 18px;
            line-height: 21px;
          }
        }

        .location {
          margin-bottom: 10px;
        }

        .title {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }

    > .btn-wrap {
      margin-top: 15px;
      gap: 15px;

      .btn {
        flex: none;
        height: 47px;
        padding: 0 80px;
      }
    }
  }

  &.list-box-no-bg {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    min-height: auto;

    > .item {
      position: relative;
      border: 0;
      padding: 0;

      + .item {
        margin-top: 20px;
        border-top: 1px solid $grey--1;
        padding-top: 20px;
      }

      .info {
        border-bottom: 0;
        padding: 0;
        flex-direction: column;

        &.info-lg {
          position: relative;

          .img {
            position: absolute;
            top: 0;
            left: 0;
            width: 240px;
            height: 120px;
          }

          .location {
            font-size: 12px;
            line-height: 14px;
          }

          .title {
            margin-bottom: 15px;
          }

          .desc {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 5px;
          }

          .price {
            font-size: 18px;
            line-height: 21px;
          }

          .item {
            align-items: flex-start;
            padding-left: 260px;

            &:nth-child(3) {
              flex-direction: column;
              align-items: flex-start;
            }
          }
        }
      }

      .info-row {
        display: flex;

        .info-col {
          + .info-col {
            margin-left: 35px;
          }
        }
      }

      .btns {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        right: 0;
        top: 0;

        .btn-like {
          margin-bottom: 20px;
        }

        .btn-row {
          display: flex;

          + .btn-row {
            margin-top: 15px;
          }

          &.mo {
            display: none;
          }

          .btn {
            display: inline-block;
            flex: none;
            padding: 0 80px;

            &.with-val {
              display: flex;
              flex-direction: column;
              padding: 15px 0;
              height: auto;

              .val {
                color: $grey--6;
                font-size: 12px;
                font-weight: 500;
              }
            }

            + .btn {
              margin-left: 10px;
            }
          }
        }
      }

      &.item-with-btn {
        padding-right: 74px;

        .btn-like {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .list-box {
    > .item {
      .info {
        flex-direction: column;

        .item {
          &:nth-child(2) {
            margin-top: 5px;
            justify-content: flex-end;
          }

          .price {
            font-size: 12px;
          }
        }

        &.info-lg {
          flex-direction: column;

          .img {
            .badge-wrap {
              .badge {
                display: none;

                &.pc {
                  display: inline-block;
                  font-size: 12px;
                  line-height: 14px;
                  padding: 5px 10px;
                }
              }

              .badge-img.mobile {
                display: inline-block;
              }
            }
          }
        }
      }

      > .btn-wrap {
        flex-direction: column;

        .btn {
          width: 100%;
        }
      }
    }

    &.list-my-property {
      > .item {
        .info {
          .item {
            flex-direction: column;
            align-items: flex-start;

            .img {
              width: 100%;
              height: auto;
              aspect-ratio: 275 / 100;
              margin: 0 0 15px;

              .badge {
                display: inline-block;
              }

              img {
                aspect-ratio: 275 / 100;
              }
            }
          }
        }

        .btn-wrap {
          gap: 10px;
        }
      }
    }

    &.list-box-no-bg {
      > .item {
        > .info {
          &.info-lg {
            display: block;

            .img {
              aspect-ratio: 335 / 120;
              height: auto;

              img {
                aspect-ratio: 335 / 120;
              }

              .badge-wrap {
                .badge {
                  display: inline-block;
                }
              }
            }

            .item {
              padding-left: 0;
              flex-direction: column;
              text-align: left;

              &:nth-child(2) {
                display: inline-block;
                width: 50%;
                vertical-align: top;
                margin-top: 15px;
              }

              &:nth-child(3) {
                display: inline-block;
                text-align: right;
                width: 50%;
                margin-top: 15px;
              }
            }

            .img {
              position: relative;
              width: 100%;
              margin: 0;
            }

            .badge-price {
              margin-left: auto;
              display: inline-block;
            }
          }
        }

        .btns {
          .btn-like {
            display: none;
          }

          .btn-row {
            width: 100%;

            .btn {
              flex: 1;
              padding: 0;
            }

            &.mo {
              display: flex;

              .btn {
                display: flex;
                align-items: center;

                span {
                  margin-left: 10px;
                }
              }
            }
          }
        }

        .info-row {
          flex-direction: column;
          align-items: flex-end;
          width: 100%;

          .info-col {
            + .info-col {
              margin-left: 0;
              margin-top: 10px;
            }

            .desc {
              margin-right: 0;
              text-align: right;
            }
          }
        }

        .btns {
          position: static;
          margin-top: 15px;
        }
      }
    }
  }
}

/* e 모니터링 */
.tip {
    display : block;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    color: $grey--6;
    margin-bottom: 6px;

    &.title {
        display: flex;
        flex-basis: 100%;
        width : 100%;
        align-items: center;
        font-size: 15px;
        color:$grey--7;
        font-weight: 600;
        margin-bottom: 15px;
        margin-top: 30px;
        &::after,&::before {
            display: inline-block;
            width: 41%;
            content : "";
            background-color: rgba(0,0,0,0.35);
            height: 1px;
            font-size: 0;
            line-height: 0;
            margin: 0 5px;
        }

    }
    &.important {
        display : inline-block;
        color:red;
        font-weight: 600;
    }
    &.bold {
        display : inline-block;
        color:black;
        font-weight: 600;
        text-decoration : underline;
    }
}

.price-label {
    display: inline;
    justify-content: right;
}

/* s 마이페이지 */
.mypage-wrap {
  padding-top: 60px;
  height: 100%;
  overflow:initial !important;

  .rec-selectbox {
      display: inline-block;
      border: 3px solid $grey--2;
      border-radius: 10px;
      width: 45%;
      font-size: 20px;
      height: auto;
      padding: 10px 10px;
      margin-left: 3%;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 500;
      text-align: center;
      &:hover, &:focus, &.active {
        font-weight: 800;
        width: 46%;
        padding: 15px 10px;
        border: 4px solid red;
      }
      &.on {
        font-weight: 800;
        width: 47%;
        padding: 15px 10px;
        border: 4px solid red;
        background-color: rgba(255, 0, 0, 0.3);
      }
      &.mini {
        width: 29%;
        margin-left: 2%;
         &.on {
            font-weight: 800;
            width: 31%;
            padding: 12px 10px;
            border: 4px solid red;
            background-color: rgba(255, 0, 0, 0.3);
          }
      }

  }

  .flat-ticket {
    display: inline;
    color : #DC143C;
    border: 2px solid #DC143C;
    margin-bottom: 0px;
    border-radius: 5px;
    padding: 3px 10px;
    font-weight: 500;
    margin-left: 10px;
    &:hover, &:focus, &.active {
        color : white;
        background-color : #DC143C;
        font-weight: 900;
        border: 5px solid #DC143C;
    }
  }

  .flat-ticket-blue {
    display: inline;
    color : #00008B;
    border: 2px solid #00008B;
    margin-bottom: 0px;
    border-radius: 5px;
    padding: 3px 10px;
    font-weight: 500;
    margin-left: 10px;
    &:hover, &:focus, &.active {
        color : white;
        background-color : #00008B;
        font-weight: 900;
        border: 5px solid #00008B;
    }
  }

  .calc-fixed {
    position: sticky;
    display:flex;
    flex-direction: column;
    background-color: white;
//     z-index: 1;
    width:100%;
    bottom:0px;
    border-top: 1px solid $grey--1;
//     height: 100%;
//     height : 10%;
    text-align : right;
    margin-bottom : 20px;
    font-size : 15px;
    font-weight: 900;
//     overflow-y : auto;
    color: black;

        &.highlight{
            color: red !important;
            font-weight: 800;
            font-size : 20px;
            z-index: 1;
        }
        &.grey{
            color: $grey--7;
            font-weight: 800;
            font-size : 20px;
        }
        &.cancel-line{
           text-decoration: line-through;
        }
    }

  .inner {
      height: 100%;
      overflow:initial !important;
  > .title {
    display: flex;
    justify-content: center;
    line-height: 24px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;

  }

  }

  .logo {
    width:50px;
    &:hover, &:focus, &.active {
        width:55px;
    }
    &.sm {
        width:40px;
        &:hover, &:focus, &.active {
            width:45px;
        }
    }
  }

   .sign-in-completed {
      text-align: center;

      .ico-completed {
        display: block;
        margin: 0 auto 20px;
      }

      .txt {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        color: $white;
        margin-bottom: 30px;
      }
    }

  .complete {
    position: relative;
    font-size: 17px;
    font-weight: 500;
    border-radius: 5px;
    padding: 0 40px 0 40px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    //background-color: $false;
    //opacity:0.8;
    .icon {
        color:#0096C0;
        margin-bottom: 10px;
    }
    .space{
        display: block;
        margin-top: 20px;
    }
    .box {
        display: block;
        font-size: 15px;
        font-weight: 400;
        background-color: $grey--0;
        padding : 20px 20px;
    }
    .line-left {
            text-align: left;
        }
  }

  .date {
    display: flex;
    justify-content: center;
    line-height: 22px;
    font-size: 22px;
    font-weight: 700;
    margin: 15px 10px 20px 15px;
    justify-content: center;
    align-items: center;
    &.center {
      &:hover, &:focus, &:active {
        color: #444444;
      }
    }
    &.left {
      margin-top: 50px;
      margin-left: 10px;
      justify-content: left;
    }
    &.subDate {
      font-size: 16px;
      color: gray;
      line-height: 17px;
      margin: 10px 10px 10px 10px;
      &:hover, &:focus, &:active {
        font-size: 16px;
        color: #444444;
      }
      &.left {
        justify-content: left;
        margin-right: 15px;
      }
      &.right {
        justify-content: right;
        margin-left: 15px;
      }
      &.arrow {
        justify-content: center;
        margin: 10px 0px 10px 0px;
      }
    }
  }

  .time {
    display: flex;
    justify-content: center;
    line-height: 22px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }


  .user-info {
    margin-bottom: 60px;

    .img {
      width: 80px;
      height: 80px;
      margin: 0 auto 15px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .user-name {
      display: block;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
    }

    .thumbnail {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .btn {
        flex: none;

        .ico {
          margin-right: 5px;
        }
      }
    }

    .info {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .item {
        position: relative;
        display: flex;
        width: 150px;
        flex-direction: column;
        align-items: center;

        + .item {
          &::before {
            content: '';
            width: 1px;
            height: 20px;
            background-color: $grey--2;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .title {
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          color: $grey--6;
          margin-bottom: 10px;
        }

        .value {
          font-size: 18px;
          line-height: 21px;
          font-weight: 700;
        }
      }
    }
  }

  .page-links {
    width: 610px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;

    .item {
      position: relative;
      width: 300px;
      border: 1px solid $grey--2;
      border-radius: 10px;
      padding: 20px 15px;

      .link {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;

        .ico {
          position: absolute;
          bottom: 20px;
          right: 20px;
        }
      }

      .ico-mypage {
        display: block;
        margin-bottom: 10px;
      }

      .title {
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .desc {
        color: $grey--5;
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
      }
    }
  }

  .btn-wrap {
    .btn {
      flex: none;
      padding: 0 80px;
      height: 61px;
      line-height: 61px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .txt-link {
    font-size: 16px;
    line-height: 17px;
    font-weight: 500;
    text-decoration: underline;
  }

  .form-box {
    max-width: 500px;
    margin: 30px auto;

    &.round {
        border: 2px solid $grey--3;
        padding: 10px 10px;
        border-radius: 10px;
    }

    .btn-wrap {
      margin-top: 60px;
    }

    .link {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      > * + * {
        margin-top: 30px;
      }
    }
  }
  .history-list {
    display: block;
    background-color: white;
    border-radius: 5px;
    margin-top: 30px;
    padding: 10px 10px;
    text-align: left;
    &:focus, &:hover, &:active {
        background-color: $grey--1;
        border-color: $grey--2;
    }
    &.DONE{
            border: 3px solid #2653DF;
        }
        &.WAITING_FOR_DEPOSIT{
            border: 3px solid #FF913C;
        }
        &.CANCELED{
            border: 3px solid #F3153C;
        }

    .rec-label {
        display: inline;
        line-height: 10px;
        font-size: 12px;
        color: $grey--7;
        font-weight: 500;
        margin-top: 15px;
        padding: 4px 4px;
        margin-left: 1px;
        margin-right: 10px;
        margin-bottom: 5px;
        background-color : black;
        border-radius: 3px;
        color: white;
        text-align: left;

        &.DONE{
            background-color : #2653DF;
        }
        &.WAITING_FOR_DEPOSIT{
            background-color : #FF913C;
        }
        &.CANCELED{
            background-color : #F3153C;
        }
        &.right {
            text-align: right;
            display: inline;
            margin-top: 2px;
            margin-bottom: 2px;
        }

    .text{
        display: block;
        text-align: left;
        width:100%;
        }
        &.right{
            text-align: right;
        }
        &.grey-mini{
           display: inline;
           font-size: 12px;
           color: $grey--5;
        }
    }
  }
}

@media (max-width: 1200px) {
  .mypage-wrap {
    padding-top: 50px;
    height: 100%;
    .calc-fixed {
      position: sticky;
      display:flex;
      flex-direction: column;
      background-color: white;
      border-top: 1px solid $grey--1;
//       z-index: 1;
      width:100%;
//       bottom:0px;
      top:0;
//       height : 100%;
      text-align : right;
      margin-bottom : 20px;
      font-size : 15px;
      font-weight: 900;
//       overflow-y : auto;
      color: black;
//       background-color: white;
      &.highlight{
        color: red !important;
        font-weight: 800;
        font-size : 20px;
      }
      &.grey{
        color: $grey--7;
        font-weight: 800;
        font-size : 20px;
      }
      &.cancel-line{
        text-decoration: line-through;
      }
    }


    .inner {
      padding: 10px 10px;

      > .title {
        margin-bottom: 20px;
      }
      .price-label {
        display: inline;
        justify-content: right;
        text-align: right;
        border: 3px solid red;
        border-radius: 10px;
        padding: 5px 5px;
        color: red;
        font-weight: 700;
      }
    }

    .page-links {
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 85px;
        padding: 15px 15px 15px 70px;

        .ico {
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
        }

        .ico-go-link {
          left: auto;
          right: 15px;
        }
      }
    }

    .form-group {
      + .form-group {
        margin-top: 20px;
      }
    }

    .user-info {
      margin-bottom: 30px;
    }

    .form-box {
      margin: 30px auto 0;
      &.round {
        border: 2px solid $grey--3;
        padding: 10px 10px;
        border-radius: 10px;
      }

      .btn-wrap {
        margin-top: 30px;

        .btn {
          flex: 1;
        }
      }

      .history-list {
            background-color: white;
            border-radius: 5px;
            margin-top: 30px;
            padding: 10px 10px;
            text-align: left;
            &:focus, &:hover, &:active {
                background-color: $grey--1;
                border-color: $grey--2;
            }

            &.DONE{
                border: 3px solid #2653DF;
            }
            &.WAITING_FOR_DEPOSIT{
                border: 3px solid #FF913C;
            }
            &.CANCELED{
                border: 3px solid #F3153C;
            }

            .rec-label {
                display: inline;
                line-height: 10px;
                font-size: 12px;
                color: $grey--7;
                font-weight: 500;
                margin-top: 15px;
                padding: 4px 4px;
                margin-left: 1px;
                margin-right: 10px;
                margin-bottom: 5px;
                background-color : black;
                border-radius: 3px;
                color: white;
                text-align: left;

                &.DONE{
                    background-color : #2653DF;
                }
                &.WAITING_FOR_DEPOSIT{
                    background-color : #FF913C;
                }
                &.CANCELED{
                    background-color : #F3153C;
                }
                &.right {
                    text-align: right;
                    display: inline;
                    margin-top: 2px;
                    margin-bottom: 2px;
                }

            .text {
                display: block;
                text-align: left;
                &.right{
                    text-align: right;
                }
                &.grey-mini{
                   display: inline;
                   font-size: 12px;
                   color: $grey--5;
                }
            }

            }
          }
    }
  }
}

/* e 마이페이지 */
.date {
    display: flex;
    justify-content: center;
    line-height: 22px;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 20px;
}

/* s 매물 등록하기 */
.add-listing-wrap {
  padding-top: 60px;

  hr {
    margin: 30px 0;
    border-top: 1px solid $grey--1;
    opacity: 1;
  }

  .heading-title {
    font-size: 20px;
    line-height: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .label {
    display: flex;
    justify-content: space-between;
    color: $grey--7;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 10px;

    .txt-right {
      font-size: 12px;
      color: $grey--5;
      line-height: 14px;
      font-weight: 500;
    }
  }

  .tip {
    color: $grey--6;
    font-size: 15px;
    line-height: 14px;
    margin-top: 20px;
  }

  .form-check-box {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 35px;
    }
  }

  .form-group {
    .content {
      background-color: #f8f8fb;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      padding: 15px;
      line-height: 17px;
      min-height: 154px;
      max-height: 154px;
      overflow-y: auto;
      margin-bottom: 10px;
    }
  }

  .inner {
    width: 900px;
  }

  .upload-img {
    .list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .item {
        flex-basis: 150px;
        aspect-ratio: 150 / 100;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .btn-wrap {
    margin-top: 15px;

    .btn {
      font-size: 14px;
      height: 57px;
    }
  }

  textarea.form-control.form-lg {
    height: 250px;
    white-space: pre-wrap;
  }

  textarea.form-control {
    height: 150px;
    resize: none;
  }

  .btn-submit {
    margin-top: 60px;

    .btn {
      flex: none;
      font-size: 18px;
      padding: 0 80px;
    }
  }
}

@media (max-width: 1200px) {
  .add-listing-wrap {
    padding-top: 45px;

    .inner {
      width: 100%;
      padding: 0 20px;

      &:first-child {
        padding: 0;
      }
    }

    .heading-title {
      text-align: center;
      margin-bottom: 20px;

      + .label {
        padding: 0 20px;
      }
    }

    .upload-img {
      padding-left: 20px;

      .list {
        display: block;
        padding-right: 20px;
        white-space: nowrap;
        overflow-x: auto;

        .item {
          display: inline-block;
          width: 150px;

          + .item {
            margin-left: 10px;
          }
        }
      }
    }

    .row {
      margin: 0;

      .col {
        padding: 0;

        + .col {
          margin-left: 15px;
        }
      }

      .form-with-btn {
        margin-bottom: 0;

        .form-control {
          padding: 0 30px 0 15px;
          white-space: pre-wrap;
        }

        + .btn-send {
          margin-top: 15px;
        }
      }

      &.row-2 {
        .col {
          width: 50%;
        }
      }

      &.row-3 {
        .col {
          width: calc(33.333333% - 15px);
        }
      }
    }

    .btn-wrap {
      padding: 0;
    }

    .btn-submit {
      position: fixed;
      bottom: 0;
      left: 0;
      margin: 0;
      z-index: 5;
      width: 100%;

      .btn {
        border-radius: 0;
        width: 100%;
      }
    }
  }
}

/* e 매물 등록하기 */

/* 달력 UI */
.calendar-ui {
  .calendar-head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    .item {
      display: flex;
      align-items: center;
      gap: 10px;

      + .item {
        margin-left: 15px;
      }

      .btn-nav {
        width: 20px;
        height: 20px;
      }

      .value {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }

  .calendar-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(48px * 7);
    margin: 0 auto 30px;

    .day {
      width: 48px;
      height: 48px;
      border-radius: 100%;
      font-size: 17px;
      font-weight: 600;
      color: #000;

      &.on {
        background-color: $primary;
        color: $white;
      }
      &.old{
      color: #857f7f;
      font-weight: 100;
      }
    }
  }
}




/* s 회사 소개 */
.intro-wrap {
  .visual {
    width: 100%;
    height: 500px;
    margin-bottom: 5px;
    &.empty {
        height: 50px;
    }

  .visual-mobile {
    width: 100%;
    height: 300px;
    margin-bottom: 5px;
    }

    img {
      width: 100%;
      height: 80%;
      object-fit: cover;
    }
  }

  .inner {
    padding: 0 0px;
  }

   .rec-branch {
      display: inline-block;
      border: 3px solid $grey--2;
      border-radius: 10px;
      width: 16%;
      font-size: 18px;
      height: auto;
      padding: 5px 8px;
      margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 700;
      text-align: center;
      &:hover, &:focus, &.active {
        font-weight: 800;
        width: 15%;
        padding: 5px 8px;
        border: 4px solid $primary;
      }
      &.on {
        font-weight: 800;
        width: 15%;
        padding: 5px 8px;
        color: white;
        border: 4px solid $primary;
        background-color: $primary;
      }
  }


  .heading-lg {
    font-size: 30px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 20px;

    br {
      display: none;
    }
  }

  .heading-desc {
    color: $grey--6;
    font-size: 18px;
    font-style: normal;
    line-height: 22px;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .img-whole {
    border-radius: 10px;
    width: 80%;
    height: auto;
    margin-bottom: 25px;
    object-fit:fill;
    overflow: visible;
  }

  .img-lg {
    border-radius: 10px;
    width: 800px;
    height: 290px;
    overflow: hidden;
    margin-bottom: 25px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .txt-box {
    margin-left : 50px;
    display: flex;
    flex-direction: column;

    &.width-50{
        width:100%;
    }

    &.moblie{
        margin-left : 0px;
    }

    .txt-lg-rec {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 25px;
      text-align: center;
      background-color: $primary;
      padding: 10px 10px;
      width: 200px;
      border-radius: 10px;
    }

    .txt-lg {
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 10px;
      margin-left: -50px;
      &.right{
        margin-left: 0px;
      }
    }
    .txt-md {
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .desc {
      color: $grey--7;
      font-size: 17px;
      line-height: 22px;
      font-weight: 600;
      opacity: .7;
      margin-bottom: 8px;
      &.right{
        margin-left: -50px;
      }
    }
    .desc-sub {
      display: inline;
      color: $grey--7;
      font-size: 17px;
      line-height: 22px;
      font-weight: 600;
      opacity: .7;
      margin-bottom: 8px;
      margin-left: 5px;
      &.rec {
        margin-left: 0px;
        border:3px solid #24A035;
        border-radius: 50px;
        line-height: 30px;
        margin-bottom : 10px;
        padding: 0px 5px;
        background-color: #24A035;
        opacity: 1;
        color: white;
      }
    }
  }

  .img-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

     > * + * {
       margin-left: 20px;
     }
    &.mobile {
        margin-left: -20px;
    }

    &.align-right {
      justify-content: flex-end;
    }
  }

  .img-col {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: column;
    &.mobile {
        margin-left: 0px;
    }

    > * + * {
      margin-left: 35px;
    }

    &.align-right {
      justify-content: flex-end;
    }
    &.width-50 {
      flex-basis: 50%;
    }
  }

  .img-sm {
    object-fit:cover;
//     width: 80%;
    //aspect-ratio: 500 / 290;
    border-radius: 5px;
    overflow: hidden;
    &.margin {
        width: 5%;
    }
    &.width {
        width: 30%;
        margin-left: 0px;
        object-fit:cover;
        aspect-ratio: 500 / 400;
        margin-right: 10px;
    }
    &.width-50 {
        width: 50%;
    }
  }

  .img-sm-mobile {
    display: block;
     width: 100%;
     padding: 5px 5px;
    //aspect-ratio: 500 / 290;
    border-radius: 20px;
    &.width {
        overflow: hidden;
        aspect-ratio: 500 / 400;
    }
  }

  .slogan {
    font-size: 45px;
    line-height: 55px;
    font-weight: 700;
    text-align: center;
    font-family: montserrat;
    margin: 100px 0;

    br {
      display: none;
    }
  }

  .sub-text {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 60px;

    .item {
      width: 212px;

      .title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 30px;
      }

      .desc {
        color: $grey--6;
        font-size: 14px;
        line-height: 22px;
        opacity: .7;
      }

      + .item {
        margin-left: 50px;
      }
    }
  }

  .img-full {
    border-radius: 10px;
    width: 100%;
    height: 200px;
    overflow: hidden;

    &.mo {
      width: 100%;
      height: 100px;
      display: none;
    }
    //aspect-ratio: 1100 / 400;
  }
}

@media (max-width: 1200px) {
  .intro-wrap {
    .visual {
      width: 100%;
      height: 400px;
      margin-bottom: 10px;
      &.empty {
        height: 50px;
      }
    }

    .rec-branch {
      display: inline-block;
      border: 3px solid $grey--2;
      border-radius: 10px;
      width: 30%;
      font-size: 16px;
      height: auto;
      padding: 5px 8px;
      margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 700;
      text-align: center;
      &:hover, &:focus, &.active {
        font-weight: 800;
        width: 30%;
        padding: 5px 8px;
        border: 4px solid $primary;
      }
      &.on {
        font-weight: 800;
        width: 30%;
        padding: 5px 8px;
        color: white;
        border: 4px solid $primary;
        background-color: $primary;
      }
  }

    .heading-lg {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 25px;

      br {
        display: block;
      }
    }

    .heading-desc {
      margin-bottom: 45px;
    }

    .img-lg {
      width: 100%;
      height: auto;
      aspect-ratio: 335 / 200;
    }

    .img-whole {
        border-radius: 10px;
        width: 100%;
        height: auto;
        margin-bottom: 25px;
        object-fit:fill;
        overflow: visible;
    }

    .inner {
      padding: 0 20px;
    }

    .img-col {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      &.width-50{
        flex-basis: 50%;
        width: 50%;
      }

      .txt-box {
//         width: 100%;
        text-align: left;
        margin: 0;
        margin-left: 50px;

        &.moblie{
            margin-left : 0px;
        }

       &.right{
        margin-left: 0px;
       }

        .desc {
            &.right {
                margin-left: -20px;
            }
        }

        .txt-lg {
          font-size: 22px;
          line-height: 24px;
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: -30px;
          &.right{
            margin-left: 0px;
          }
        }
        .txt-md {
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 5px;
        }
      }

      .img-sm {
        object-fit:cover;
        border-radius: 5px;
        margin: 5px 5px;
//         height: 400px;

        img {
          height: auto;
        }
        &.width {
            display: inline;
            width: 25%;
        }
      }



      &.align-right {
        flex-direction: column-reverse;
      }
    }

    .img-row {
      margin-bottom: 10px;
      > * + * {
      margin-left: 10px;
      }
      &.mobile {
        flex-direction: column;
      }

      .txt-box {
//         width: 100%;
        text-align: left;
        margin: 0;
        margin-left: 50px;
        &.moblie{
            margin-left : 0px;
        }
        &.right {
            margin-left: 20px;
        }

        .desc {
            &.right {
                margin-left: -20px;
            }
        }

        .txt-lg {
          font-size: 22px;
          line-height: 24px;
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: -30px;
          &.right{
            margin-left: 0px;
          }
        }
        .txt-md {
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 5px;
        }
      }

      .img-sm {
        border-radius: 5px;
        margin: 0 0 0px;
//         height: 400px;

        img {
          height: auto;
        }
        &.width {
            display: inline;
            margin-left:2%;
            width: 30%;
        }
      }



      &.align-right {
        flex-direction: column-reverse;
      }
    }

    .slogan {
      margin: 60px 0;

      br {
        display: block;
      }
    }

    .img-full {
      border-radius: 0;
      display: none;
      aspect-ratio: 375 / 400;
      height: auto;
      margin-bottom: 25px;

      &.mo {
        display: block;
      }
    }

    .sub-text {
      flex-direction: column;
      margin-bottom: 0;

      .item {
        width: 100%;

        .title {
          margin-bottom: 20px;
        }

        + .item {
          margin-left: 0;
          margin-top: 30px;
        }
      }
    }
  }
}
/* e 회사 소개 */

/* s 이벤트 상세 */
.event-wrap {
  padding-top: 60px;
  text-align: center;

  .inner {
    padding: 0 50px;
  }

  .banner {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    background-color: #3F53BC;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 25px;
  }

  .date {
    color: $grey--5;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 10px;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 25px;
  }

  .desc {
    color: $grey--6;
    font-size: 14px;
    line-height: 22px;
    width: 500px;
    margin: 0 auto 60px;
  }

  .btn-wrap {
    text-align: center;

    .btn {
      display: inline-block;
      flex: none;
    }
  }
}

@media (max-width: 1200px) {
  .event-wrap {
    .inner {
      padding: 0 20px;
    }

    .desc {
      width: 100%;
      margin: 0 auto 30px;
    }
  }
}

/* e 이벤트 상세 */
