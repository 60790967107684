@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  src: url(../../public/fonts/Pretendard/Pretendard-Light.woff2) format('woff2'),
  url(../../public/fonts/Pretendard/Pretendard-Light.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  src: url(../../public/fonts/Pretendard/Pretendard-Regular.woff2) format('woff2'),
  url(../../public/fonts/Pretendard/Pretendard-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  src: url(../../public/fonts/Pretendard/Pretendard-Medium.woff2) format('woff2'),
  url(../../public/fonts/Pretendard/Pretendard-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  src: url(../../public/fonts/Pretendard/Pretendard-SemiBold.woff2) format('woff2'),
  url(../../public/fonts/Pretendard/Pretendard-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  src: url(../../public/fonts/Pretendard/Pretendard-Bold.woff2) format('woff2'),
  url(../../public/fonts/Pretendard/Pretendard-Bold.woff) format('woff');
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 700;
  src: url(../../public/fonts/Montserrat/Montserrat-Bold.woff) format('woff');
}
