$font-family-base: 'Pretendard';
$primary: #DF9843;
$false: #FF426F;
$black: #1E1E20;
$white: #ffffff;
$grey--0: #F8F8FB;
$grey--1: #F4F3F8;
$grey--2: #EDEDF3;
$grey--3: #E8E7ED;
$grey--4: #D8D8E0;
$grey--5: #BDBEC6;
$grey--6: #A0A0A6;
$grey--7: #68686D;
